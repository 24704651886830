import React from "react";
import ConnectionState from "./ConnectionState";
import {useState, useEffect} from "react";
import {useMap} from "react-leaflet";
import "../InfoBox.css";
import 'moment/locale/it';
import useInterval from "../hooks/useInterval";
import axios from 'axios';
import InfoBoxTitleResponsive from "./InfoBoxTitleResponsive";
import MediaResponsive from "./MediaResponsive";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import VideoPlayerStreamingRespVideojs from "./VideoPlayerStreamingRespVideojs";

export default function InfoBoxResponsiveNoIos(props) {
    const [infoBoxId, setInfoboxId] = useState(props.index);
    const videoPlayerKey = props.videoPlayerKey || 0; // Chiave unica per forzare il re-render della componente VideoPlayerStreamingTest
    const [videoStreamingH265, setVideoStreamingH265] = useState(props.urlVideoStreamingH265);
    const [videoStreamingH264, setVideoStreamingH264] = useState(props.urlVideoStreamingH264);
    const [node, setNode] = useState(props.singleSnData);
    const [videoStreamingName, setVideoStreamingName] = useState(node?.point?.info?.MISSION_NUM);
    const [imagePoint, setImagePoint] = useState(null);
    const [isToggled, setToggled] = useState(false);
    const [iconLinkStatus, setIconLinkStatus] = useState("fa-chevron-up");
    const [infoBoxContainer, setInfoBoxContainer] = useState("");
    const [infoBoxPopupContainer, setInfoBoxPopupContainer] = useState("");
    const [linkStatus, changeLinkStatus] = useState("SEARCHING");
    const [linkStatusStreaming, changeLinkStatusStreaming] = useState("SEARCHING");
    const [pdv, setPdv] = useState(null);
    const [allData, setAllData] = useState(props.allData);
    const serialiStreaming = props.serialiStreaming;
    const streamingH265 = props.streamingH265;
    const streamingH264 = props.streamingH264;
    const streamingDrone = props.streamingDrone;
    const [seriale, setSeriale] = useState("Seriali ...");
    const [imageData, setImageData] = useState(null);
    const [lastFlyToPosition, setLastFlyToPosition] = useState();
    const [userTipo, setUserTipo] = useState(props.userTipo);
    const [bigMediaPic, setBigMediaPic] = useState(null);
    const [typeBigImage, setTypeBigImage] = useState("IR_IMAGE");
    const [videoOpen, setVideoOpen] = useState(false);
    const [photoOpen, setPhotoOpen] = useState(false);
    const [streamingOpen, setStreamingOpen] = useState(false);

    const [gradiLat, setGradiLat] = useState(node.point.points.LATITUDE);
    const [minutiLat, setMinutiLat] = useState(node.point.points.LATITUDE);
    const [secondiLat, setSecondiLat] = useState(node.point.points.LATITUDE);

    const [gradiLon, setGradiLon] = useState(node.point.points.LONGITUDE);
    const [minutiLon, setMinutiLon] = useState(node.point.points.LONGITUDE);
    const [secondiLon, setSecondiLon] = useState(node.point.points.LONGITUDE);

    const [infoboxStatesStreaming, setInfoboxStatesStreaming] = useState(props.infoboxStatesStreaming);

    const map = useMap();

    const [convertedAngle, setConvertedAngle] = useState(null);

    const flyZoomOptions = {
        animate: true, duration: 0.5
    }

    const apiClient = axios.create({
        baseURL: props.htkBackend,
        withCredentials: true,
    })

    useEffect(() => {
        setInfoboxId(infoBoxId);
      }, [infoBoxId]);

    useEffect(() => {
        setInfoboxStatesStreaming(props.infoboxStatesStreaming);
    }, [props.infoboxStatesStreaming]);
/*
    useEffect(() => {
        if (linkStatusStreaming !== 'CONNECTED') {
          setStreamingOpen(false);
        }
      }, [linkStatusStreaming]); 
*/
      useEffect(() => {
        if (linkStatusStreaming !== 'CONNECTED') {
          //setVideoOpen(false);
          props.setInfoboxStatesStreaming(prevStates => ({
            ...prevStates,
            [props.ALIAS]: false,
          }));
        }
      }, [linkStatusStreaming]);

    useEffect(() => {
        setUserTipo(props.userTipo);
    }, [props.userTipo]);

    useEffect(() => {
    }, [props.allData])

    var moment = require('moment-timezone');
    //const dateTimeStamp = moment.utc(node.point.time.YEAR + "-" + node.point.time.MONTH + "-" + node.point.time.DAY + " " + node.point.time.HOUR + ":" + node.point.time.MINUTE + ":" + node.point.time.SECOND).local();
    const dateTimeStamp = moment.utc(`${node.point.time.YEAR}-${node.point.time.MONTH}-${node.point.time.DAY} ${node.point.time.HOUR}:${node.point.time.MINUTE}:${node.point.time.SECOND}`, 'YYYY-MM-DD HH:mm:ss').local();

    const toggleTrueFalse = () => {
        setToggled(!isToggled);

        if(isToggled){   
            if(props.infoboxStatesPhoto[props.ALIAS]){
                setInfoBoxContainer("infoBoxFoto");
                setInfoBoxPopupContainer("infoBoxFotoCont");
            }
            else{
                setInfoBoxContainer("");
                setInfoBoxPopupContainer("");
            }
        }
        else{  
            if(props.infoboxStatesPhoto[props.ALIAS]){
                setInfoBoxContainer("infoBoxTotale");
                setInfoBoxPopupContainer("infoBoxTotaleCont");
            }
            else{
                setInfoBoxContainer("infoBoxDati");
                setInfoBoxPopupContainer("infoBoxDatiCont");
            }
        }

    };

    const tooglePhotoPanel = () => {
        //setPhotoOpen(!photoOpen);
        props.togglePhoto(props.ALIAS);

        if(props.infoboxStatesPhoto[props.ALIAS]){   
            if(isToggled){
                setInfoBoxContainer("infoBoxDati");
                setInfoBoxPopupContainer("infoBoxDatiCont");
            }
            else{
                setInfoBoxContainer("");
                setInfoBoxPopupContainer("");
            }
        }
        else{  
            if(isToggled){
                setInfoBoxContainer("infoBoxTotale");
                setInfoBoxPopupContainer("infoBoxTotaleCont");
            }
            else{
                setInfoBoxContainer("infoBoxFoto");
                setInfoBoxPopupContainer("infoBoxFotoCont");
            }
        }
    }

    /*
    const toogleStreamingPanel = () => {
        setStreamingOpen(!streamingOpen);
    }
    */
   
    useInterval(() => {checkPDV(node.SN)}, 1000);

    const checkPDV = async(sn) => {

        apiClient.get(props.htkBackend + "/datalive/PDV/" + sn)
            .then((response) => {
                if (response.status == 200) {
                    if ( response.data.status == "noPDV") {

                    }  else {
                        setPdv(response.data);
                        setSeriale(response.data.seriale.seriale);
                    }

                }
            })
    };


    useEffect(() => {
        let imageData = allData.filter((singleNode) => {
            if ( singleNode.point.hasOwnProperty("images")) {
                if ( singleNode.point.images.RGB_IMAGE != "") {
                    return true
                } else {
                    return false;
                }
            }
        });
        imageData = imageData.slice(0, 4);
        setImageData(imageData);
    }, [props.allData]);

    useEffect( () => {
        if (props.infoboxStates[props.ALIAS]) {
            map.flyTo([node.point.points.LATITUDE, node.point.points.LONGITUDE],map.getZoom(), flyZoomOptions);
        }
    }, [props.infoboxStates[props.ALIAS]]);

    useEffect( () => {
        props.setInfoboxStates(props.infoboxStates);
    }, [props.infoboxStates]);

    function flyToCentering()  {
        map.flyTo([node.point.points.LATITUDE, node.point.points.LONGITUDE],map.getZoom(), flyZoomOptions)
    }

    const openImagePop = (nodeImage, typeImage) => {
        setBigMediaPic(nodeImage);
        setTypeBigImage(typeImage);

    }

    /*
    const toogleVideoPanel = () => {
        setVideoOpen(!videoOpen);
    }
*/
    const _eventClosePreview = () => {
        setBigMediaPic(null);
    }

    const _fnTitleInfoBox2 = () => {
        switch (userTipo) {
            case 1:
                return (
                    <h4>{pdv?.alias}</h4>
                )
            case 2:
                return (
                    <div className="font-23">{pdv?.seriale.seriale}/{pdv?.marca}/{pdv?.alias}</div>
                )
            case 3:
                return (
                    <h4>{pdv?.marca}</h4>
                )
            default:
                return (
                    <div>Loading ...</div>
                )
        }
    } ;

    useEffect( () => {
        setNode(props.singleSnData);
        if(node.Direction < 0){
            setConvertedAngle(node.Direction + 360);
        }
        else{
            setConvertedAngle(node.Direction);
        }

        let diffLat = 9999;
        let diffLon = 9999;

        if ( lastFlyToPosition) {
            diffLat = Math.abs(lastFlyToPosition[0]-node.point.points.LATITUDE);
            diffLon = Math.abs(lastFlyToPosition[1]-node.point.points.LONGITUDE);
        }

        if (props.infoboxStates[props.ALIAS]) {

            if ( diffLat > 0.0001  || diffLon > 0.0001) {
                map.flyTo([node.point.points.LATITUDE, node.point.points.LONGITUDE],map.getZoom(), flyZoomOptions);
                setLastFlyToPosition([node.point.points.LATITUDE, node.point.points.LONGITUDE]);
            } else {

            }
        }

        if ( node?.point?.images) {
            let n={};
            n.info = node.point.info;
            n.images = node.point.images;
            setImagePoint(n);
        }

    }, [props.singleSnData]);

    useEffect(() => {
        setGradiLat(Math.floor(node.point.points.LATITUDE));
        setMinutiLat(Math.floor((node.point.points.LATITUDE - gradiLat)*60));
        setSecondiLat((((node.point.points.LATITUDE - gradiLat)*60) - minutiLat)*60);

        setGradiLon(Math.floor(node.point.points.LONGITUDE));
        setMinutiLon(Math.floor((node.point.points.LONGITUDE - gradiLon)*60));
        setSecondiLon((((node.point.points.LONGITUDE - gradiLon)*60) - minutiLon)*60);
    }, [props.singleSnData]);

    return (
               
            <div className={"d-flex flex-column ContenitorePopupInfoBox " + infoBoxPopupContainer}>
                
                <div className={"d-flex flex-column InfoBoxResponsive " + infoBoxContainer} >
            
                    <div className={"d-flex flex-row justify-center-content title "  + linkStatus} onTouchStart={toggleTrueFalse} >
                        
                        <div className="d-flex align-items-center divInfoBox">
                            <InfoBoxTitleResponsive pdv={pdv} userTipo={userTipo} htkBackend={props.htkBackend} />
                        </div>

                        {   
                            node?.point?.video ?
                                <div className="d-flex align-items-center flex-center ml-auto pr-1">
                                    <span class="popuptext-cell" id="myPopup-cell"> 
                                        <img src="./icons/iconaRegistrazioneVideoMobile.gif" className="iconRec-cell" alt="Mia Immagine" />
                                    </span>
                                </div>
                            : <></>
                        } 

                        {   
                            node?.point?.images ?
                                <div className="d-flex align-items-center flex-center ml-auto pr-1">
                                    <span class="popuptext2-cell" id="myPopup-cell"> 
                                        <img src="./icons/iconaImageRec.webp" className="iconRec2-cell" alt="Mia Immagine" />
                                    </span>
                                </div>
                            : <></>
                        } 

                        <div className="d-flex align-items-center ml-auto">
                            <i className={"d-flex ms-auto align-item-center fas sizeFreccia " + iconLinkStatus}></i>
                        </div>

                    </div>

                    
                    <div className="d-flex flex-row divButton w-100 flex-center">
                        
                            <button className={"btnAction photoButt " + (props.infoboxStatesPhoto[props.ALIAS] ? " toogleTrue " : "")} onTouchStart={tooglePhotoPanel}><div className="fontPhotoFollow">Photo</div></button>
                        
                            <button className={"btnAction followButt " + (props.infoboxStates[props.ALIAS] ? " toogleTrue " : "")} onTouchStart={() => {props.toggleFollow(props.ALIAS);props.setFlyTo(previousFlyTo => !previousFlyTo);}}><div className="fontPhotoFollow">Follow</div></button>
                            
                                {serialiStreaming.includes(seriale) && (
                                    linkStatusStreaming === "CONNECTED" ? (

                                    <button className={`btnAction streamingButt ${infoboxStatesStreaming[props.ALIAS] ? 'toogleTrue' : ''}`} onTouchStart={() => {props.toggleStreaming(props.ALIAS)}}>
                                        <div className="fontStreamingFollow">Stream</div>
                                    </button>
                                    ) : (
                                    <button className={"btnAction streamingButt inactiveButton "} disabled>
                                        <div className="fontStreamingFollow">Stream</div>
                                    </button>
                                    )
                                )}
                    </div>
                     
                    <div className={props.infoboxStatesPhoto[props.ALIAS] ? " w-100" : " d-none"}>
                        <div className="w-100">
                            <MediaResponsive pic={imagePoint} openPopImage={openImagePop}  />
                        </div>
                    </div>
                    
                    <div className="divider mt-1 font-24"></div>

                    {   
                        serialiStreaming.includes(seriale) && linkStatusStreaming === "CONNECTED" && infoboxStatesStreaming[props.ALIAS] &&

                        // player per cellulare non IOS (componente responsive video.js)
                        
                        <div className="streamingContainer">

                            <VideoPlayerStreamingRespVideojs 
                                videoStreaming={streamingH265.includes(seriale) ? videoStreamingH265 : videoStreamingH264} 
                                nomeDispositivo={seriale} 
                                videoStreamingName={videoStreamingName} 
                                infoBoxId={infoBoxId} 
                                videoPlayerKey={videoPlayerKey} 

                                isDrone={streamingDrone.includes(seriale)} // Verifica se il seriale appartiene a un drone
                            />

                        </div>

                    } 
                    
                    {      
                        !infoboxStatesStreaming[props.ALIAS] &&
                        <>

                    <div className="d-flex flex-column justify-content-between w-100 flex-center">

                        <div className="valueInfoBox2 d-flex flex-row align-items-center ml-1 mt-1"> 
                            <p className="labelInfoBoxResponsive mr-1">Lat</p>
                            <p className="mr-1">{gradiLat.toFixed(0)}°</p>
                            <p className="mr-1">{minutiLat.toFixed(0)}'</p>
                            <p className="mr-1">{secondiLat.toFixed(3)}''</p>
                        </div>

                        <div className="valueInfoBox2 d-flex flex-row align-items-center ml-1 mt-1"> 
                            <p className="labelInfoBoxResponsive mr-1">Lon</p>
                            <p className="mr-1">{gradiLon.toFixed(0)}°</p>
                            <p className="mr-1">{minutiLon.toFixed(0)}'</p>
                            <p className="mr-1">{secondiLon.toFixed(3)}''</p>
                        </div>
                        
                    </div>

                    <div className="information d-flex flex-row justify-content-between">

                        <div className="d-flex flex-column align-items-center mr-1">
                            
                            <div className="labelInfoBoxResponsive">
                                Heading
                            </div>
                            <div className="valueInfoBox">
                                {convertedAngle && convertedAngle.toFixed(0)}
                            </div>

                            {
                                userTipo!=1 ?
                                    (<div className="d-flex flex-column align-items-center">
                                        <div className="labelInfoBoxResponsive">Alt </div>
                                            <div className="valueInfoBox">
                                                {node.point.points.ALTITUDE.toFixed(2)}
                                            </div>
                                    </div>)
                                    : (<div/>)
                            }
                        </div>
                    
                        <div className="d-flex flex-column align-items-center">
                                
                                <div className="labelInfoBoxResponsive">
                                    Speed
                                </div>
                                <div className="valueInfoBox">
                                    {(node.point.points.SPEED*3.6).toFixed(0) < 5 ? "0" : (node.point.points.SPEED*3.6).toFixed(0)} km/h
                                </div>

                                <div className="labelInfoBoxResponsive">
                                    Speed
                                </div>
                                <div className="valueInfoBox">
                                    {(node.point.points.SPEED*3.6).toFixed(0) < 5 ? "0" : (node.point.points.SPEED*1.944).toFixed(0)} kn
                                </div>
                        
                        </div>

                    </div>

                    </>
                    }
                    
                    <div className="information divInfoSotto">
                        
                        <ConnectionState userTipo={userTipo} node={node} setLinkStatus = {changeLinkStatus} setLinkStatusStreaming = {changeLinkStatusStreaming}/>
                    </div>


                </div>
            </div>
            
    )
}