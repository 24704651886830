import React, {useState, useEffect, useRef}  from "react";
import {Marker, Polyline, Popup, useMap} from "react-leaflet";
import L from "leaflet";
import { popupContent, popupHead, popupText, okText } from "../popupStyles";
import Moment from 'react-moment';
import 'leaflet-rotatedmarker';

export default function UserObject(props) {
    
    const position = [props.sessione.latitude, props.sessione.longitude];
    const email = props.sessione.email;

    const generalRelIcon = L.icon({
        iconUrl: './icons/iconaUomo.png',
        iconSize: [36, 36],
        iconAnchor: [16, 16],
    });

    //console.log("USEROBJECT ---->  " , position);
    
    return (
        <Marker position={position} icon={generalRelIcon}>
            <Popup>
                <div>
                    <div>
                        Latitude: {props.sessione.latitude}
                    </div>
                    <div>
                        Longitude: {props.sessione.longitude}
                    </div>
                    <div>
                        {/*Detected at: <Moment format= "HH:mm:ss">{sessione.timestamp}</Moment>*/}
                    </div>
                    <div>
                        {email}
                    </div>
                </div>
            </Popup>
        </Marker>
    );


}