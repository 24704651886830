import React, {useState, useEffect, useRef, useLayoutEffect, useMapEvents} from "react";
import {useMap} from "react-leaflet";
import FlyObjectPanelButton from "./FlyObjectPanelButton";
import Moment from 'react-moment';
import moment from 'moment';


export default function ControlPanel(props) {
    const [zoomLevel, setZoomLevel] = useState(1);
    //const [userAuthController, setUserAuthController] = useState(props.userAuthController);
    const [serialsList, setSerials] = useState(props.listSns);
    const [userTipo, setUserTipo] = useState(props.userTipo);
    const [iconLinkStatus, setIconLinkStatus] = useState("fa-chevron-up");
    const [styleScroll, setStyleScroll] = useState("scrollBar");
    const [controlPanelLaterale2, setControlPanelLaterale2] = useState("ControlPanelLaterale2-open");
    const [controlPanelLaterale2_2, setControlPanelLaterale2_2] = useState("ControlPanelLaterale2-2-open");
    const [divWindowCalc, setDivWindowCalc] = useState((window.innerHeight/2)- 50);
    
        //console.log("serial list : " , serialsList);
   

    let stateButton = false;

    function setZoomLevelCB(zl) {
        props.callBackSetGeneralZoomMap(zl);
        setZoomLevel(zl);
    }

    useEffect(() => {setUserTipo(props.userTipo)}, [props.userTipo])

    useEffect(() => {
        setSerials(props.listSns);
        props.setSerialsAttivi(props.listSns);

    }, [props.listSns]);
/*
    useEffect(() => {
        setUserAuthController(props.userAuthController);
    }, [props.userAuthController]);
*/
    useEffect(() => {

        //console.log("userAuth ? " + userAuthController);

        const updateDivWindowCalc = () => {
          setDivWindowCalc((window.innerHeight / 2) - 50);
          //console.log(divWindowCalc);


        if(divWindowCalc > (serialsList.length * 47) + 80){

            //console.log(divWindowCalc + ">" +  ((serialsList.length * 47) + 80) + " ?");
            //console.log("dim Window > listaCalcolata");

            const element = document.querySelector('.ControlPanelLaterale2-open');
            if (element) {
            element.style.height = (serialsList.length * 47) + 80 + "px";
            }

            const element3 = document.querySelector('.ControlPanelLaterale2-1');
                
            if(element3){
                element3.style.height = "40px";
            }

            const element2 = document.querySelector('.ControlPanelLaterale2-2-open');

            if (element2) {
                element2.style.height = (serialsList.length * 47) + 10 + "px";
            }
        }
        else{

            //console.log(divWindowCalc + "<" +  ((serialsList.length * 47) + 80)  + " ?");
            //console.log("dim Window < listaCalcolata");


            const element = document.querySelector('.ControlPanelLaterale2-open');
            
            if (element) {
                element.style.height = divWindowCalc + "px";
            }

            const element3 = document.querySelector('.ControlPanelLaterale2-1');
                
            // vi aggiungo css 
            if(element3){
                element3.style.height = "40px";
            }

            const element2 = document.querySelector('.ControlPanelLaterale2-2-open');

            if (element2) {
                element2.style.height = divWindowCalc - 60 + "px";
            }

        }


        };
      
        window.addEventListener('resize', updateDivWindowCalc);
      
        updateDivWindowCalc();
/*
        if(userAuthController == null){
            const controlPanelUn = document.querySelector(".ControlPanelLaterale2-unauthorized");

            controlPanelUn.style.height = "100px";
        }
  */    
        return () => {
          window.removeEventListener('resize', updateDivWindowCalc);
        };
    }, [serialsList.length, divWindowCalc/*,userAuthController*/]);

        
        const changeClick = () => {
            //console.log("ChangeClick : ControlPanel");
            props.setControlClick(true);
        };

        const changeClick2 = () => {
            //console.log("ChangeClick2 : ControlPanel");
            props.setControlClick(false);
        };

      const clickMenu = () => {

        if(styleScroll == "scrollBar"){ 

            //console.log("styleScroll == scrollBar  ::  " + styleScroll);

            const element = document.querySelector('.scrollBar');
            const element3 = document.querySelector('.ControlPanelLaterale2-1');
            
            // vi aggiungo css 

            element3.style.height = "40px";
            
            if (element) {
                //element.style.display = 'none';
                //element.style.pointerEvents = 'none';
              }

            setStyleScroll("scrollBarClicked");

        }
        else{ //apro
            //console.log("styleScroll == scrollBarClicked  ::  " + styleScroll);

            const element = document.querySelector('.scrollBarClicked');
            
            const element3 = document.querySelector('.ControlPanelLaterale2-1');

            element3.style.height = "40px";
            element.style.display = 'block';
            element.style.pointerEvents = 'auto';
            element.style.height = (serialsList.length * 47) + 10 + "px";

            setStyleScroll("scrollBar");
            
        }

        if(controlPanelLaterale2 == "ControlPanelLaterale2-open"){  // chiudo
            //console.log("controlPanelLaterale2 == ControlPanelLaterale2-open  ::  " + controlPanelLaterale2);
            
            const element = document.querySelector('.ControlPanelLaterale2-open');
            const element2 = document.querySelector('.ControlPanelLaterale2-2-open');

            const element3 = document.querySelector('.ControlPanelLaterale2-1');
            
            element.style.height = "52px";

            element3.style.height = "40px";
            
            if(element2){
                //element2.style.display = "none";
                //element2.style.pointerEvents = 'none';
            }

            setControlPanelLaterale2("ControlPanelLaterale2-close");
            setControlPanelLaterale2_2("ControlPanelLaterale2-2-close");
            
        }
        else{ 

            if(divWindowCalc > (serialsList.length * 47) + 80){

                //console.log(divWindowCalc + ">" +  ((serialsList.length * 47) + 80) + " ?");
                //console.log("dim Window > listaCalcolata");

                const element = document.querySelector('.ControlPanelLaterale2-close');
                if (element) {
                element.style.height = (serialsList.length * 47) + 80 + "px";
                }

                const element3 = document.querySelector('.ControlPanelLaterale2-1');
                    
                if(element3){
                    element3.style.height = "40px";
                }

                const element2 = document.querySelector('.ControlPanelLaterale2-2-close');

                if (element2) {
                    element2.style.height = (serialsList.length * 47) + 10 + "px";
                }
            }
            else{

                //console.log(divWindowCalc + "<" +  ((serialsList.length * 47) + 80)  + " ?");
                //console.log("dim Window < listaCalcolata");

                const element = document.querySelector('.ControlPanelLaterale2-close');
                
                if (element) {
                    element.style.height = divWindowCalc + "px";
                }

                const element3 = document.querySelector('.ControlPanelLaterale2-1');
                    
                // vi aggiungo css 
                if(element3){
                    element3.style.height = "40px";
                }

                const element2 = document.querySelector('.ControlPanelLaterale2-2-close');

                if (element2) {
                    element2.style.height = divWindowCalc - 60 + "px";
                }

            }

            setControlPanelLaterale2("ControlPanelLaterale2-open");
            setControlPanelLaterale2_2("ControlPanelLaterale2-2-open");

                        
        }


        if(iconLinkStatus == "fa-chevron-up"){
            setIconLinkStatus("fa-chevron-down")
        }
        else{
            setIconLinkStatus("fa-chevron-up")
        }

        props.setControlClick(true);
    }

    return(
            <>
                {
                    /*userAuthController && */serialsList.length>0 ? (


                        <div className={"d-flex flex-column " + controlPanelLaterale2} /*onMouseOver={changeClick} onMouseOut={changeClick2}*/>

                            <div className="d-flex flex-row intestazioneDevices ControlPanelLaterale2-1">

                                <h4 className="ControlPanelLateraleTitle mr-10">Devices</h4>

                                <div className="divContainerButtSeleziona">
                                    <button className="btn buttRetryControlPanel" type="submit" onClick={clickMenu}>
                                        <div className={"fas " + iconLinkStatus}>
                                        </div>
                                    </button>
                                </div>

                            </div>


                            <div className={"d-flex flex-column flex-center " + controlPanelLaterale2_2}>

                                <div className={"ControlPanelLaterale2-2-1 " + styleScroll}>
                                    {serialsList
                                        .sort((a, b) => a.alias.localeCompare(b.alias))
                                        //.sort((a, b) => a.sn.localeCompare(b.sn))
                                        .map((sn) => (
                                            <FlyObjectPanelButton
                                                htkBackend={props.htkBackend}
                                                userTipo={userTipo}
                                                key={sn.sn}
                                                setMainFlyObject={props.setMainFlyObject}
                                                stateButton={props.buttonStates[sn.sn] || false}
                                                sn={sn}
                                                cambiaButton={props.cambiaButton}
                                            />
                                        ))}
                                </div>

                            </div>


                        </div>



                    )
                    : 
                    (
                        <div className={"d-flex flex-column ControlPanelLaterale2-unauthorized"} onMouseOver={changeClick} onMouseOut={changeClick2}>
            
                            <div className="d-flex flex-row intestazioneDevices ControlPanelLaterale2-1">

                                <h4 className="ControlPanelLateraleTitle mr-10">Devices</h4>

                                <div className="divContainerButtSeleziona">
                                    <button className="btn buttRetryControlPanel" type="submit">
                                        <div className={"fas fa-chevron-up"}>
                                        </div>
                                    </button>
                                </div>

                            </div>


                            <div className="d-flex flex-column ControlPanelLaterale2-2">
                                <div><i className="fad fa-circle-exclamation"></i> No authorized device for this account</div>
                            </div>

                        </div>
                    )}
            </>
        );
}
