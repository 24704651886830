import React, { useRef, useState, useEffect } from 'react';
import Hls from 'hls.js';

const VideoPlayerResponsiveHls = ({ videoStreaming, nomeDispositivo, videoStreamingName, isDrone }) => {
  const videoRef = useRef(null);

  const [videoSrc, setVideoSrc] = useState('');

  useEffect(() => {
    const updateVideoSrc = () => {
      const newVideoSrc = isDrone
      ? `${videoStreaming}/${nomeDispositivo}/index.m3u8`
      : `${videoStreaming}/${nomeDispositivo}/${videoStreamingName}/index.m3u8`;
      setVideoSrc(newVideoSrc);
    };

    updateVideoSrc();
  }, [videoStreaming, nomeDispositivo, videoStreamingName, isDrone]);

  useEffect(() => {
    const initializeHls = () => {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(videoSrc);
        hls.attachMedia(videoRef.current);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
            if (videoRef.current) {
              videoRef.current.play();
            }
          });
      }
      else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        videoRef.current.src = videoSrc;
        videoRef.current.addEventListener('canplay', function () {
          if (videoRef.current) {
            videoRef.current.play();
          }
        });
      }
    };

    initializeHls();
  }, [videoSrc]);

  return (
    <video
      autoPlay
      controls
      ref={videoRef}
      className="custom-video-player-cell"
    ></video>
  );
};

export default VideoPlayerResponsiveHls;