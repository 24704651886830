import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

function VideoPlayerStreamingRespVideojs(props) {
  const videoNode = useRef(null);
  const playerRef = useRef(null);

  const [videoStreaming, setVideoStreaming] = useState(props.videoStreaming);
  const [nomeDispositivo, setNomeDispositivo] = useState(props.nomeDispositivo);
  const [videoStreamingName, setVideoStreamingName] = useState(props.videoStreamingName);

  const [key, setKey] = useState(generateUniqueKey());

  useEffect(() => {
    setVideoStreaming(props.videoStreaming);
    setNomeDispositivo(props.nomeDispositivo);
    setVideoStreamingName(props.videoStreamingName);
    setKey(generateUniqueKey());
  }, [props.videoStreaming, props.nomeDispositivo, props.videoStreamingName]);

  useEffect(() => {
    const initializePlayer = () => {

      const videoSource = props.isDrone
        ? `${videoStreaming}/${nomeDispositivo}/index.m3u8`
        : `${videoStreaming}/${nomeDispositivo}/${videoStreamingName}/index.m3u8`;

      playerRef.current = videojs(videoNode.current, {
        autoplay: true,
        controls: true, 
        sources: [
          {
            // src: videoStreaming + "/" + nomeDispositivo + "/" + videoStreamingName + "/index.m3u8",
            src: videoSource,
            type: 'application/x-mpegURL',

          }
        ],
      });
      playerRef.current.on('error', handleVideoError);
    };

    const handleVideoError = (event) => {
      console.error('Errore nel caricamento del video:', event);
      
      setKey(generateUniqueKey);
    };
    initializePlayer();

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, [videoStreaming, nomeDispositivo, videoStreamingName, key]); 

  function generateUniqueKey() {
    return `${videoStreaming}-${nomeDispositivo}-${videoStreamingName}-${Date.now()}`;
  }

  return (
    <div key={key} data-vjs-player className='containerStreamingResp'>
      <video ref={videoNode} className="video-js"></video>
    </div>
  );
}

export default VideoPlayerStreamingRespVideojs;