import React, {useState, useEffect, useRef} from "react"

export default function BigMedia(props) {
    const [mediaPics, setMediaPics] = useState([]);
    const [typeImage, setTypeImage] = useState("IR_IMAGE");

    const mediaPicsRef = useRef(mediaPics);

    useEffect(() => {
        setMediaPics(props.pic);
        //console.log(mediaPics);
    }, [props.pic])

    useEffect(() => {
        setTypeImage(props.typeBigImage);
    }, [props.typeBigImage])

    function getImage(picSingle) {
        if ( typeImage == "RGB_IMAGE") {
            return (<div>
                        <div className="d-flex w-100" onClick={ () => {closeImage()}}>x</div>
                        <img className="m-1 border" src={`data:image/png;base64,${picSingle.RGB_IMAGE}`} onClick={ () => {toogleImage()}}  width="100%"  />
                    </div>);
        } else if ( typeImage == "IR_IMAGE") {
            return (
                <div>
                    <div className="d-flex w-100" onClick={ () => {closeImage()}}>x</div>
                    <img className="m-1 border" src={`data:image/png;base64,${picSingle.IR_IMAGE}`} onClick={ () => {toogleImage()}} width="100%"  />
                </div>);
        }
    }

    function closeImage() {
        setMediaPics(null);
        props.eventClosePreview();
    }

    function toogleImage() {
        if ( typeImage == "IR_IMAGE") {
            setTypeImage("RGB_IMAGE");
        }
        if ( typeImage == "RGB_IMAGE") {
            setTypeImage("IR_IMAGE");
        }
    }

    return (<div className="d-flex flex-row w-100 justify-content-center p-2">
        {

        }

        {
            mediaPics ? getImage(mediaPics) : <div />
        }
    </div>);
}
