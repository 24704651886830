import React, {useState, useEffect, useRef}  from "react";
import {Marker, Polyline, Popup, useMap} from "react-leaflet";
import L from "leaflet";
import { popupContent, popupHead, popupText, okText } from "../popupStyles";
import Moment from 'react-moment';
import 'leaflet-rotatedmarker';

export default function FlyObject(props) {
    const isMob = props.isMob;
    const [rilevazioniState, setRilevazioni] = useState(props.rilevazioni);
    const [isOpenImage, setIsOpenImage] = useState(false);
    const [isOpenImageResponsive, setIsOpenImageResponsive] = useState(false);
    const [classOpenImage, setOpenImage] = useState("");
    const [classOpenImageResponsive, setOpenImageResponsive] = useState("");
    const [borderImages, setBorderImages] = useState("");
    const [borderImagesResponsive, setBorderImagesResponsive] = useState("initialStateContenitoreResponsive");
    const [colorAutoAssign, setColorAutoAssign] = useState(generateRandomColor());
    const [marker, setMarker] = useState(null);
    const map = useMap();
    const [isDrone, setIsDrone] = useState(false);

    useEffect(() => {
        setIsDrone((props.streamingDrone).includes(props.SN));
      }, []); // Dipendenze vuote per eseguire solo al montaggio

    useEffect(() => {

        isOpenImage ? setOpenImage("dimensioniImmagini") : setOpenImage("");
        isOpenImage ? setBorderImages("contenitoreBorderImages") : setBorderImages("");

        const popupContent = document.querySelector('.selez1');
        if (popupContent) {
            if(isOpenImage){
                popupContent.style.width = '760px';
            }
            else{
                popupContent.style.width = '300px';
            }
        }

        isOpenImageResponsive ? setOpenImageResponsive("openImagePopup-cell") : setOpenImageResponsive("");
        isOpenImageResponsive ? setBorderImagesResponsive("contenitoreBorderImagesResponsive") : setBorderImagesResponsive("initialStateContenitoreResponsive");
        
        const popupContentCell = document.querySelector('.selez1Responsive');
        if (popupContentCell) {
            if(isOpenImageResponsive){
                popupContentCell.style.width = '350px';
            }
            else{
                popupContentCell.style.width = '180px';
            }
        }


      },[isOpenImage, isOpenImageResponsive]);

    const handleMarkerMounted = (ref) => {
        if (ref) {
          setMarker(ref);
        }
      };

    const handleMarkerAdd = () => {
        if (marker) {
            marker.setRotationOrigin('center center');
        }
    };
   
    const heliIcon = L.icon({
        iconUrl: './icons/PosizioneElicottero.png',
        iconSize: new L.point(40, 40),
        iconAnchor: [20, 20],
    });

    const droneIcon = L.icon({
        iconUrl: './icons/iconaDrone.png',
        iconSize: new L.point(40, 40),
        iconAnchor: [20, 20],
    });

    const generalRel = L.icon({
        iconUrl: './icons/PuntoGPS.png',
        iconSize: new L.point(20, 20),
        iconAnchor: [10, 10],
    });

    const startIcon = L.icon({
        iconUrl: './icons/PosizioneIniziale.png',
        iconSize: new L.point(32, 32),
        iconAnchor: [16, 16],
    });

    const imageIcon = L.icon({
        iconUrl: './icons/IconaFoto.png',
        iconSize: new L.point(40, 40),
        iconAnchor: [16, 16],
    });

    function generateRandomColor(){
        let maxVal = 0xFFFFFF; // 16777215
        let randomNumber = Math.random() * maxVal;
        randomNumber = Math.floor(randomNumber);
        randomNumber = randomNumber.toString(16);
        let randColor = randomNumber.padStart(6, 0);
        return `#${randColor.toUpperCase()}`
    }

    const optionsPolyline = {
        weight:2,
        color:colorAutoAssign,
    };

    const toggleOpenCloseImagePopup = () => {
        setIsOpenImage(!isOpenImage);

        isOpenImage ? setOpenImage("dimensioniImmagini") : setOpenImage("");

    };

    const openPanel = () => {
        //console.log("open panel FlyObj.js");
        const navbar = document.querySelector('.barraNavigazione');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            navbar.style.marginTop = '0px';
        }

        const bottone = document.querySelector('.contenitoreIcona2');
        if(bottone){
            bottone.classList.remove('visible');
            bottone.classList.add('invisible');
        }

        const controlPanel = document.querySelector('.ControlPanelLaterale');
        if(controlPanel){
            controlPanel.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            controlPanel.style.marginTop = '0px';
        }
    }
    
    const closePanel = () => {
        //console.log("close panel FlyObj.js");

        const navbar = document.querySelector('.barraNavigazione');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            const altezzaNavbar = navbar.offsetHeight;
            navbar.style.marginTop = `-${altezzaNavbar}px`;    
        }

        setTimeout(() => {
            const bottone = document.querySelector('.contenitoreIcona2');
            if(bottone){
                bottone.classList.remove('invisible');
                bottone.classList.add('visible');
            }
        }, 10);
    }

    const handleImageClick = () => {
        setIsOpenImage(!isOpenImage);
        isOpenImage ? setOpenImage("dimensioniImmagini") : setOpenImage("");
        isOpenImage ? setBorderImages("contenitoreBorderImages") : setBorderImages("");

        if(isOpenImage){
            openPanel();
        }
        else
        {
            closePanel();
        }
        
        // Modifica le dimensioni del popup utilizzando il metodo CSS
        const popupContent = document.querySelector('.selez1');
        if (popupContent) {
            if(isOpenImage){
                popupContent.style.width = '760px';
            }
            else{
                popupContent.style.width = '300px';
            }
        }

          if(props.infoboxStates[props.ALIAS] == false){
            
          }
          else{
            props.toggleFollow(props.ALIAS);
          }

    };

    const toggleOpenCloseImagePopupResponsive = () => {
        setIsOpenImageResponsive(!isOpenImageResponsive);
        isOpenImageResponsive ? setOpenImageResponsive("openImagePopup-cell") : setOpenImageResponsive("");
        isOpenImageResponsive ? setBorderImagesResponsive("contenitoreBorderImagesResponsive") : setBorderImagesResponsive("initialStateContenitoreResponsive");
        // Modifica le dimensioni del popup utilizzando il metodo CSS
        const popupContent = document.querySelector('.selez1Responsive');
        if (popupContent) {
            if(isOpenImage){
                popupContent.style.width = '350px';
            }
            else{
                popupContent.style.width = '180px';
            }
        }

          if(props.infoboxStates[props.ALIAS] == false){
            
          }
          else{
            props.toggleFollow(props.ALIAS);
          }
    };

    useEffect( () => {
        setRilevazioni(props.rilevazioni);
    }, [props.rilevazioni]);



    let pCoord = rilevazioniState.map((singlePoint) => {
        return [singlePoint.point.points.LATITUDE, singlePoint.point.points.LONGITUDE];
    })


    const handleOpenPopup = (e) => {
      
        const popupContainer = e.popup.getElement();
        popupContainer.style.width = '300px';

        if(isOpenImage == true){
            setIsOpenImage(false);
        }

    };


    const handleClosePopup = (e) => {
      
        openPanel();

    };

    const handleOpenPopupResponsive = (e) => {

        
        setIsOpenImageResponsive(false);

        const popupContainer = e.popup.getElement();
        popupContainer.style.width = '180px';

        if(isOpenImageResponsive == true){
            setIsOpenImageResponsive(false);
        }

    };

        return (<div>
                {
                    rilevazioniState.map((singlePoint, index) => {

                        // if (index === rilevazioniState.length - 1) {
                        //     console.log(`Marker Direction Leaflet : ${singlePoint.Direction}`);
                        // }

                        let numberRilevazioni = rilevazioniState.length;
                        
                        let position = [singlePoint.point.points.LATITUDE, singlePoint.point.points.LONGITUDE];

                        let gradiLat = Math.floor(singlePoint.point.points.LATITUDE);
                        let minutiLat = Math.floor((singlePoint.point.points.LATITUDE - gradiLat)*60);
                        let secondiLat = (((singlePoint.point.points.LATITUDE - gradiLat)*60) - minutiLat)*60;
                        
                        let gradiLon = Math.floor(singlePoint.point.points.LONGITUDE);
                        let minutiLon = Math.floor((singlePoint.point.points.LONGITUDE - gradiLon)*60);
                        let secondiLon = (((singlePoint.point.points.LONGITUDE - gradiLon)*60) - minutiLon)*60;
                        
                        let iconToRender = generalRel;
                        let imgExist = false;
                        let iconDirectionAngle = singlePoint.Direction;
                        iconToRender = generalRel;

                        if ( singlePoint.point.images !== undefined) {

                            if ( singlePoint.point.images.RGB_IMAGE !== "" || singlePoint.point.images.IR_IMAGE !== "") {
                                iconToRender = imageIcon;
                                imgExist = true;
                                iconDirectionAngle = 0;
                            }
                        }

                        if (index == rilevazioniState.length-1) {
                            if(isDrone){
                                iconToRender = droneIcon;
                            }
                            else{
                                iconToRender = heliIcon;
                            }
                        }


                        if ( index == numberRilevazioni-1) {
                            //map.flyTo(position);
                        }

                        //
                        if (!isMob) {
                            return (
                        
                                    <Marker
                                        key={`marker-${singlePoint.point.info.MISSION_NUM}-${singlePoint.point.info.PKT_NUM}`}
                                        position={position}
                                        icon={iconToRender}
                                        //icon={imageIcon}
                                        //icon={iconToRender}
                                        rotationAngle={singlePoint.Direction}
                                        eventHandlers={{
                                            add: handleMarkerAdd,
                                            popupopen : handleOpenPopup,
                                            popupclose: handleClosePopup
                                        }}
                                        ref={handleMarkerMounted}
                                    >

                                    <Popup className="request-popup selez1"  /* onClose={handleClosePopup} style={{ width: popupWidth }}*/>
                                        <div className={"request-popup selez2 "+ borderImages}>
                                            <div className={"coordinate d-flex flex-column p-1 selez3 "+ borderImages}>
                                                <div className="mt-1 w-100 d-flex flex-column flex-center">
                                                    
                                                        <div className="flex-center">
                                                            <h5 className="mr-2">Lat: </h5>
                                                            <h5 className="mr-1">{gradiLat.toFixed(0)}°</h5>
                                                            <h5 className="mr-1">{minutiLat.toFixed(0)}'</h5>
                                                            <h5 className="mr-1">{secondiLat.toFixed(3)}''</h5>
                                                        </div>
                                                    
                                                        <div className="flex-center">
                                                            <h5 className="mr-2">Lon:</h5>
                                                            <h5 className="mr-1">{gradiLon.toFixed(0)}°</h5>
                                                            <h5 className="mr-1">{minutiLon.toFixed(0)}'</h5>
                                                            <h5 className="mr-1">{secondiLon.toFixed(3)}''</h5>
                                                        </div>
                                                    
                                                </div>
                                                <div className="mt-2">
                                                    <span className="h6">Rilevato alle:&nbsp;
                                                        <Moment format= "HH:mm:ss" date={singlePoint?.point?.time?.YEAR + "-" + singlePoint?.point?.time?.MONTH + "-" + singlePoint?.point?.time?.DAY + " " + singlePoint?.point?.time?.HOUR + ":" + singlePoint?.point?.time?.MINUTE + ":" + singlePoint?.point?.time?.SECOND} ></Moment>
                                                        {/*&nbsp;( <Moment
                                                                parse="YYYY-MM-DD HH:mm:ss"
                                                                date={  singlePoint?.point?.time?.YEAR + "-" +
                                                                        singlePoint?.point?.time?.MONTH + "-" +
                                                                        singlePoint?.point?.time?.DAY + " " +
                                                                        singlePoint?.point?.time?.HOUR + ":" +
                                                                        singlePoint?.point?.time?.MINUTE + ":" +
                                                                        singlePoint?.point?.time?.SECOND
                                                                    }
                                                                toNow ago trim
                                                        >
                                                        </Moment> fa. )*/}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={"d-flex flex-column p-2 selez3 "+ borderImages}>
                                                {/* imgExist ? (<div className="mb-1">Burns point: {singlePoint?.point?.images.NUM_BURN_POINTS}</div>) : "" */}
                                                <div className="d-flex flex-row coordinate ">
                                                    {/* imgExist ? <img onClick={toggleOpenCloseImagePopup} className = {"img-responsive mini-img-popup m-2 " + classOpenImage} src={`data:image/jpeg;base64,${singlePoint.point.images.RGB_IMAGE}`} /> : "" */}
                                                    {/* imgExist ? <img onClick={toggleOpenCloseImagePopup} className = {"img-responsive mini-img-popup m-2 " + classOpenImage} src={`data:image/jpeg;base64,${singlePoint.point.images.IR_IMAGE}`} /> : "" */}
                                                    
                                                    {/*    QUELLO CHE STA ORA IN PRODUZIONE   */}
                                                    { imgExist ? <img onClick={handleImageClick} className = {"img-responsive mini-img-popup-pc m-2 " + classOpenImage} src={`data:image/jpeg;base64,${singlePoint.point.images.RGB_IMAGE}`} /> : ""  }
                                                    { imgExist ? <img onClick={handleImageClick} className = {"img-responsive mini-img-popup-pc m-2 " + classOpenImage} src={`data:image/jpeg;base64,${singlePoint.point.images.IR_IMAGE}`} /> : ""  }

                                                    {/*    QUELLO CHE DI TEST   */}
                                                    {/* <img onClick={handleImageClick} className = {"img-responsive mini-img-popup-pc m-2 " + classOpenImage} src={'./brandIcons/USERS.png'} /> */}
                                                    {/* <img onClick={handleImageClick} className = {"img-responsive mini-img-popup-pc m-2 " + classOpenImage} src={'./brandIcons/USERS.png'} /> */}

                                                </div>

                        {/*
                                                <div className="d-flex flex-row popup-telemetria">
                                                    <div className="d-flex flex-row p-2">
                                                        <div className="mr-1"><i className={"fad fa-code-commit " + (!singlePoint.point.telemetria.GPIO_STATUS ? " disable-icon-telemetria" : "" )}></i></div>
                                                        <div className="mr-1"><i className={"fad fa-location-circle "  + (!singlePoint.point.telemetria.GPIO_STATUS ? " disable-icon-telemetria" : "" )}></i></div>
                                                        <div className="mr-1"><i className={"fad fa-video " + (!singlePoint.point.telemetria.IR_STAT ? " disable-icon-telemetria" : "" )}></i></div>
                                                        <div className="mr-1"><i className={"fad fa-image-polaroid "  + (!singlePoint.point.telemetria.RGB_STAT ? " disable-icon-telemetria" : "" )}></i></div>
                                                    </div>
                                                    <div className="d-flex flex-row ml-auto">
                                                        <div className="p-2">
                                                            <i className={"fal fa-satellite " }></i>&nbsp;
                                                            <span className="small">{singlePoint.point.points.SAT_VIS}</span>/<span className="small">{singlePoint.point.points.SAT_USED}</span>
                                                        </div>

                                                    </div>
                                                    <div className="mb-1 small ml-auto p-2">idPoint: {singlePoint.point.info.MISSION_NUM}-{singlePoint.point.info.PKT_NUM}</div>
                                                </div>
                        */}

                                            </div>
                                        </div>
                                    </Popup>

                                
                            
                            </Marker>
                            );
                            }else {
                                return (
                                    <Marker
                                        key={`marker-${singlePoint.point.info.MISSION_NUM}-${singlePoint.point.info.PKT_NUM}`}
                                        position={position}
                                        icon={iconToRender}
                                        rotationAngle={singlePoint.Direction}
                                        eventHandlers={{
                                            add: handleMarkerAdd,
                                            popupopen : handleOpenPopupResponsive
                                        }}
                                        ref={handleMarkerMounted}
                                    >

                                    <Popup className="request-popup selez1Responsive">
                                        <div className={"divPopup "+ borderImagesResponsive}>
                                            <div className={"coordinate d-flex flex-column "+ borderImagesResponsive}>
                                            <div className="mt-1 w-100 d-flex flex-column flex-center">
                                                    
                                                    <div className="flex-center">
                                                        <h7 className="mr-2 font-weight-responsive">Lat: </h7>
                                                        <h7 className="mr-1">{gradiLat.toFixed(0)}°</h7>
                                                        <h7 className="mr-1">{minutiLat.toFixed(0)}'</h7>
                                                        <h7 className="mr-1">{secondiLat.toFixed(3)}''</h7>
                                                    </div>
                                                
                                                    <div className="flex-center">
                                                        <h7 className="mr-2 font-weight-responsive">Lon:</h7>
                                                        <h7 className="mr-1">{gradiLon.toFixed(0)}°</h7>
                                                        <h7 className="mr-1">{minutiLon.toFixed(0)}'</h7>
                                                        <h7 className="mr-1">{secondiLon.toFixed(3)}''</h7>
                                                    </div>
                                                
                                            </div>
                                                <div className="mt-2"><span className="h7 font-weight-responsive">Rilevato alle:&nbsp;
                                                    <Moment format= "HH:mm:ss" date={singlePoint?.point?.time?.YEAR + "-" + singlePoint?.point?.time?.MONTH + "-" + singlePoint?.point?.time?.DAY + " " + singlePoint?.point?.time?.HOUR + ":" + singlePoint?.point?.time?.MINUTE + ":" + singlePoint?.point?.time?.SECOND} ></Moment>
                                                    {/*&nbsp;( <Moment
                                                            parse="YYYY-MM-DD HH:mm:ss"
                                                            date={  singlePoint?.point?.time?.YEAR + "-" +
                                                                    singlePoint?.point?.time?.MONTH + "-" +
                                                                    singlePoint?.point?.time?.DAY + " " +
                                                                    singlePoint?.point?.time?.HOUR + ":" +
                                                                    singlePoint?.point?.time?.MINUTE + ":" +
                                                                    singlePoint?.point?.time?.SECOND
                                                                }
                                                            toNow ago trim
                                                    >
                                                            </Moment> fa. )*/}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={"d-flex flex-column pt-1 "+ borderImagesResponsive} >
                                                {/* imgExist ? (<div className="mb-1">Burns point: {singlePoint?.point?.images.NUM_BURN_POINTS}</div>) : "" */} 
                                                <div className="d-flex flex-row coordinate">
                                                    { /* imgExist ? <img onClick={toggleOpenCloseImagePopupResponsive} className = {"img-responsive mini-img-popup m-2 " + classOpenImage} src={`data:image/jpeg;base64,${singlePoint.point.images.RGB_IMAGE}`} /> : "" */}
                                                    { /* imgExist ? <img onClick={toggleOpenCloseImagePopupResponsive} className = {"img-responsive mini-img-popup m-2 " + classOpenImage} src={`data:image/jpeg;base64,${singlePoint.point.images.IR_IMAGE}`} /> : "" */}
                                                    { imgExist ? <img onClick={toggleOpenCloseImagePopupResponsive} className = {"img-responsive mini-img-popup-cell m-2 " + classOpenImageResponsive} src={`data:image/jpeg;base64,${singlePoint.point.images.RGB_IMAGE}`} /> : ""  }
                                                    { imgExist ? <img onClick={toggleOpenCloseImagePopupResponsive} className = {"img-responsive mini-img-popup-cell m-2 " + classOpenImageResponsive} src={`data:image/jpeg;base64,${singlePoint.point.images.IR_IMAGE}`} /> : ""  }
                                                </div>

                            {/*
                                                <div className="d-flex flex-row popup-telemetria">
                                                    <div className="d-flex flex-row p-1">
                                                        <div className="mr-1"><i className={"fad fa-code-commit " + (!singlePoint.point.telemetria.GPIO_STATUS ? " disable-icon-telemetria" : "" )}></i></div>
                                                        <div className="mr-1"><i className={"fad fa-location-circle "  + (!singlePoint.point.telemetria.GPIO_STATUS ? " disable-icon-telemetria" : "" )}></i></div>
                                                        <div className="mr-1"><i className={"fad fa-video " + (!singlePoint.point.telemetria.IR_STAT ? " disable-icon-telemetria" : "" )}></i></div>
                                                        <div className="mr-1"><i className={"fad fa-image-polaroid "  + (!singlePoint.point.telemetria.RGB_STAT ? " disable-icon-telemetria" : "" )}></i></div>
                                                    </div>
                                                    <div className="d-flex flex-row ml-auto">
                                                        <div className="p-1">
                                                            <i className={"fal fa-satellite " }></i>&nbsp;
                                                            <span className="small">{singlePoint.point.points.SAT_VIS}</span>/<span className="small">{singlePoint.point.points.SAT_USED}</span>
                                                        </div>

                                                    </div>
                                                    <div className="small ml-auto p-1">idPoint: {singlePoint.point.info.MISSION_NUM}-{singlePoint.point.info.PKT_NUM}</div>
                                                </div>
                            */}

                                            </div>
                                        </div>
                                    </Popup>

                        </Marker>  
                                );
                            }
                        
                    }
                    )
                }

                <Polyline positions={pCoord} pathOptions={optionsPolyline} />
            </div>
        );
}