import React, {useState, useEffect} from "react";
import {useMap} from "react-leaflet";

export default function FlyObjectPanelButtonWindy(props) {
    const [userTipo, setUserTipo] = useState(props.userTipo);
    const [stateButton, setStateButton] = useState(props.stateButton);
    const [sn, setSn] = useState(props.sn);

    const clickButton =  (sn) => {
        setStateButton(!stateButton);
        props.setMainFlyObject(sn, stateButton, sn?.alias);

        props.cambiaButton(sn, !stateButton, sn?.alias);
    };

    useEffect(() => {
        setStateButton(props.stateButton);
    }, [props.stateButton]);

    useEffect(() => {setUserTipo(props.userTipo)}, [props.userTipo]);

    return (<div className="p-1 w-100">
        <button className={"btn-block btn " + (stateButton ? " btn-warning" : "")} onClick={ () => { clickButton(sn)}} >

            <div className="d-flex flex-row">
                <div className="mr-auto"><img src={props.htkBackend + "/storage/" + sn?.iconTD} width="22" className="p-1" /></div>
                <div className="">{userTipo==3 ? (sn?.marca ) : ("")} <i className="fa-solid fa-angles-right"></i> {sn?.alias}</div>

            </div>
        </button>
    </div>);
}
