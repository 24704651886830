import React from 'react';


export default function directionFunction(p2, p1) {
    var lat1 = parseFloat(p2.lat);
    var lat2 = parseFloat(p1.lat);

    var lon1 = parseFloat(p2.lng);
    var lon2 = parseFloat(p1.lng);

    var x = parseFloat(lon2-lon1);
    var y = parseFloat(lat2-lat1);

    var g = parseFloat((lat2-lat1) / (lon2-lon1));
    var grade_rotation =radians_to_degrees(Math.atan2(x, y));

    return grade_rotation;

}

function radians_to_degrees(radians) {
    var pi = Math.PI;
    return radians * (180/pi);
}
