import React, {useRef, useState, useEffect} from "react";
import Moment from 'react-moment';
import moment from 'moment';

export default function ConnectionState(props) {
    const [node, setNode] = useState(props.node);
    const [stateConnection, setStateConnection] = useState("waitData");
    const [secsFromLastData, setSecsFromLastData] = useState(0);
    let moment = require('moment-timezone');

    useEffect(() => {
        setNode(props.node);
        //const dateTimeStamp = moment.utc(node.point.time.YEAR + "-" + node.point.time.MONTH + "-" + node.point.time.DAY + " " + node.point.time.HOUR + ":" + node.point.time.MINUTE + ":" + node.point.time.SECOND).local();
        const dateTimeStamp = moment.utc(`${node.point.time.YEAR}-${node.point.time.MONTH}-${node.point.time.DAY} ${node.point.time.HOUR}:${node.point.time.MINUTE}:${node.point.time.SECOND}`, 'YYYY-MM-DD HH:mm:ss').local();

        let lastUpdateDate = moment.utc(dateTimeStamp).local();
        var duration = moment.duration(moment().diff(lastUpdateDate));
        let timeAgoLastUpdate = moment().toNow("mm");

        let secsFromLastData = duration.asMinutes();

        if ( secsFromLastData > 30) {
            setStateConnection("lostConnection");
            setSecsFromLastData(secsFromLastData);
            props.setLinkStatus ("LOST");
        } else {
            setStateConnection("Connected");
            setSecsFromLastData(secsFromLastData);
            props.setLinkStatus ("CONNECTED");
        }

        if ( secsFromLastData > 2) {
            props.setLinkStatusStreaming("LOST");
        } else {
            props.setLinkStatusStreaming("CONNECTED");
        }

    }, [props.node])

    return (<div>
        {/*{stateConnection}({secsFromLastData.toFixed(0)}))*/}

    </div>);
}
