import axios from 'axios';
import React, { useReducer, useState, useEffect } from 'react';
import {useMap} from "react-leaflet";
import useInterval from "./../hooks/useInterval";
import Share from "./Share";


export default function AuthController(props) {

    const map = useMap();

    // axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const isMob = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const [switchMappa3DCount, setSwitchMappaCount3D] = useState(0);

    const [windowInitW, setWindowInitW] = useState(window.innerWidth);

    const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

    const [isNavbarOpen, setNavbarOpen] = useState(true);

    const [isHovered, setIsHovered] = useState(false);

    const [switchCount, setSwitchCount] = useState(0);

    const [latitude, setLatitude] = useState(40.783712);

    const [longitude, setLongitude] = useState(14.698841);

    const [zoom, setZoom] = useState(7);

    const [serialsList, setSerials] = useState(props.listSns);

    const [userTipo, setUserTipo] = useState(props.userTipo);

    const [userLatitude, setUserLatitude] = useState(null);
    const [userLongitude, setUserLongitude] = useState(null);
    const [isGeolocationEnabled, setIsGeolocationEnabled] = useState(false);
    const [watchId, setWatchId] = useState(null);

    const [isMappaLeaflet, setIsMappaLeaflet] = useState(true);

    const [sessioni, setSessioni] = useState([]);

    const [email, setEmail] = useState(null);

    let touchStartTime;
/*
    useEffect(() => {
        // Funzione per gestire la risposta della geolocalizzazione
        function handleGeolocationSuccess(position) {
            const lat = position.coords.latitude;
            const long = position.coords.longitude;
            setUserLatitude(lat);
            setUserLongitude(long);
        }

        // Funzione per gestire l'errore della geolocalizzazione
        function handleGeolocationError(error) {
            console.error('Errore nella geolocalizzazione:', error);
            // Puoi decidere cosa fare in caso di errore, ad esempio inviare la richiesta senza la posizione
        }

        // Controlla se il browser supporta la geolocalizzazione
        if (navigator.geolocation) {
            // Ottieni gli aggiornamenti sulla posizione corrente dell'utente
            const watchId = navigator.geolocation.watchPosition(handleGeolocationSuccess, handleGeolocationError);

            // Ritorna una funzione di cleanup per smettere di ricevere aggiornamenti sulla posizione
            return () => navigator.geolocation.clearWatch(watchId);
        } else {
            console.error('La geolocalizzazione non è supportata.');
            // Puoi decidere cosa fare se la geolocalizzazione non è supportata, ad esempio inviare la richiesta senza la posizione
        }
    }, []); // Effetto viene eseguito solo quando il componente viene montato
*/

    // Funzione per gestire la risposta della geolocalizzazione
    function handleGeolocationSuccess(position) {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
        setUserLatitude(lat);
        setUserLongitude(long);
    }

    // Funzione per gestire l'errore della geolocalizzazione
    function handleGeolocationError(error) {
        console.error('Errore nella geolocalizzazione:', error);
        // Puoi decidere cosa fare in caso di errore, ad esempio inviare la richiesta senza la posizione
    }

    // Funzione per abilitare/disabilitare la geolocalizzazione
    function toggleGeolocation() {
        if (!isGeolocationEnabled) {
        if (navigator.geolocation) {
            const id = navigator.geolocation.watchPosition(handleGeolocationSuccess, handleGeolocationError);
            setWatchId(id);
            setIsGeolocationEnabled(true);
        } else {
            console.error('La geolocalizzazione non è supportata.');
            // Puoi decidere cosa fare se la geolocalizzazione non è supportata, ad esempio inviare la richiesta senza la posizione
        }
        } else {
        if (watchId !== null) {
            navigator.geolocation.clearWatch(watchId);
        }
        setWatchId(null);
        setIsGeolocationEnabled(false);
        setUserLatitude(null);
        setUserLongitude(null);
        }
    }

    function changeMap() {

        // console.log("Cambio mappa...");

        setIsMappaLeaflet(!isMappaLeaflet);

        let mappaLeaflet = document.querySelector("#mappaLeaflet");
        mappaLeaflet.style.display = "none";


    }


    useEffect(() => {
        setSerials(props.listSns);
        props.setSerialsAttivi(props.listSns);

    }, [props.listSns]);

    useEffect(() => {setUserTipo(props.userTipo)}, [props.userTipo])
/*
    useEffect(() => {

        setTimeout(function() {
        
            // Crea un nuovo div con id "embed-zoom"
            var embedZoomDiv = document.createElement("div");
            embedZoomDiv.id = "embed-zoom-div";

            // Imposta le proprietà CSS con !important
            embedZoomDiv.style.setProperty('z-index', '100000000000', 'important');
            // embedZoomDiv.style.setProperty('border', '1px solid black', 'important'); // Se necessario, puoi anche aggiungere il bordo
            embedZoomDiv.style.setProperty('margin-right', '-5px', 'important');
            embedZoomDiv.style.setProperty('position', 'absolute', 'important');
            embedZoomDiv.style.setProperty('left', '10px', 'important');
            embedZoomDiv.style.setProperty('top', '100px', 'important');
            embedZoomDiv.style.setProperty('transform', 'scale(.85, .85)', 'important');

            // Ottieni il riferimento al div "windy"
            var windyDiv = document.getElementById("windy");

            if(windyDiv)
            {
                console.log("aggiunta div embed zoom...");
                // Aggiungi il nuovo div come figlio del div "windy"
                windyDiv.appendChild(embedZoomDiv);
            }
        }, 5000);

    }, []);
*/
    useEffect(() => {
        const handleOrientationChange = (event) => {
            //console.log("isPortrati : " + event.matches);
            if(event.matches){ // portrait
                const controlPanel = document.querySelector('.ControlPanelLateraleResponsive');
                if(controlPanel){
                    controlPanel.style.marginTop = '0px';
                }
            }
            else{ // landscape
                const controlPanel = document.querySelector('.ControlPanelLateraleResponsive');
                if(controlPanel){
                    controlPanel.style.marginTop = '0px';
                }
            }
            //console.log("orientation change");
          setIsPortrait(event.matches);
        };
    
        const mediaQuery = window.matchMedia("(orientation: portrait)");
        mediaQuery.addEventListener("change", handleOrientationChange);
    
        return () => {
          mediaQuery.removeEventListener("change", handleOrientationChange);
        };
      }, []);

    
    const _logga = (...any) => {
        // console.log("[htk][login]", ...any);
    }

    const logga = props.logger || _logga;

    let _csrfCookie = null;

    const [currentUser, setCurrentUser] = React.useState(
        JSON.parse(sessionStorage.getItem('loggedUser'))
    );

   const delay = 2000;

    // const delay = 500;

    const apiClient = axios.create({
        baseURL: props.apibase,
        withCredentials: true,
    })

    const userSave = (userData) => {
        
        _logga("userdata found:", userData);
        let curUser = null;
        
        if (userData) {
            _logga("current user:", userData.email);
            curUser = {
                email: userData.email,
                name: userData.name,
                surname: userData.surname,
                completeName: userData.name + " " + userData.surname
            };
            props._fnUserTipo(userData.tipo);
        }
        
        if (curUser != currentUser) {
            _logga("Login status changed to", curUser)
            props.onLoginToggle(curUser);
            //props.onUserChange(curUser); // passaggio del valore dell'utente alla componente padre
            setCurrentUser(curUser);
            sessionStorage.setItem('loggedUser', JSON.stringify(curUser));
        }
    }

    const _userSessionCheck = () => {
        logga("Checking user session")

        if (props.accessMode) {
            logga("Utente guest code");
            userSave({
                email: 'guest@hightek.it',
                name: 'Guest'
            });
            return;
        }

        // controllare se viene forzato il render a cause di useState
        apiClient.get('/testing/json/user')
            .then((response) => {
                if (response.status !== 200) {
                    throw "user session not found";
                }

                logga("Utente loggato:", response.data.email)
                userSave(response.data)
            })
            .catch((response) => {
                logga("Errore: ", response);
                userSave(null);
            })
    }

        //console.log("Dati dell'utente loggato : ", currentUser);

        
    useEffect(() => {
            //console.log("esiste current user?", currentUser);

            //console.log("email?", currentUser.email);

            if(currentUser !== null){
                // Effettua la richiesta solo se currentUser contiene dati
                if (userLatitude !== null && userLongitude !== null) {
                    // caso in cui fornisci posizione
                    // POST per notificare il server
                    apiClient.post('/testing/sessioneLive', {
                        email: currentUser.email,
                        latitude: userLatitude,
                        longitude: userLongitude,
                        route: "https://dev-qlive.hightek.it"
                    })
                    .then((response) => {
                        //console.log('Risposta dal server ---->:', response);
                    })
                    .catch((error) => {
                        if (error.response && error.response.data) {
                            console.error('Errore nella risposta --->:', error.response.data);
                        } else {
                            console.error('Errore nella risposta --->:', error.message);
                        }
                    });
                }
                else{
                    // caso in cui non fornisci posizione
                    // POST per notificare il server
                    apiClient.post('/testing/sessioneLive', {
                        email: currentUser.email,
                        route: "https://dev-qlive.hightek.it"
                    })
                    .then((response) => {
                        //console.log('POST ---> Risposta dal server ---->:', response);
                    })
                    .catch((error) => {
                        /*
                        if (error.response && error.response.data) {
                            console.error('Errore nella risposta --->:', error.response.data);
                        } else {
                            console.error('Errore nella risposta --->:', error.message);
                        }
                        */
                        console.error('Errore nella risposta --->:', error);
                    });
                }

                // GET per ricervere le coordinate degli utenti connessi al sito

                // Effettua la richiesta solo se currentUser contiene dati
                apiClient.get('/testing/getSessioniLive', {
                    params: {
                        email: currentUser.email
                    }
                })
                .then((response) => {
                   //console.log('GET ---> Dati ottenuti dal server:', response.data.sessioni);
                    setSessioni(response.data.sessioni);
                    // Fai qualcosa con i dati ottenuti, ad esempio aggiornare lo stato del componente
                    //setSessioni(response.data); // Imposta i dati delle sessioni nello stato

                })
                .catch((error) => {
                    console.error('GET ---> Errore nella richiesta al server:', error);
                    // Gestisci eventuali errori
                });
              
            }
    
    }, [currentUser]);

    // Chiamiamo la funzione di callback ogni volta che sessioni viene aggiornato
    useEffect(() => {
        props.onSessioniChange(sessioni);
    }, [sessioni, props.onSessioniChange]);

    // Chiamiamo la funzione di callback ogni volta che email viene aggiornato
    useEffect(() => {
        props.onEmailChange(email);
    }, [email, props.onEmailChange]);

    const _tokenGet = async () => {
        if (!_csrfCookie) {
            logga("CSRF Token not set, searching in cookies..")
            _csrfCookie = document.cookie
                .split('; ')
                .find(row => row.startsWith('XSRF-TOKEN='))
        }

        if (!_csrfCookie) {
            logga("CSRF Cookie not found, requesting CSRF Token", `${props.apibase}/sanctum/csrf-cookie`)
            apiClient.get('/sanctum/csrf-cookie').then(() => {
                logga("Setting cookie");
                _tokenGet();
            }).catch(err => {
                logga("Error obtaining token", err);
            })
            return
        }

        logga("_csrfCookie is", _csrfCookie.split('=')[1])
       //console.log("csrf token : ", _csrfCookie);
    }

    const loginLaunch = (e) => {
        _tokenGet()
        // _logga("login api base", props.apibase, props.apibase.trimEnd('/'));
        const loginPage = props.apibase.replace(/\/+$/, '') + `/login?htk_redirect=${window.location.href}`;
        window.location.href = loginPage;
    }

    function handleTouchStart() {
        touchStartTime = new Date().getTime();
    }

    function handleTouchEnd(event) {
        const touchEndTime = new Date().getTime();
        const touchDuration = touchEndTime - touchStartTime;
        
        if (touchDuration < 200) { // Tempo massimo per considerare un tocco come clic
            //console.log("Tocco eseguito");
            onTouchMissions(event);
        }
    }

    function handleTouchStartUser() {
        touchStartTime = new Date().getTime();
    }

    function handleTouchEndUser(event) {
        const touchEndTime = new Date().getTime();
        const touchDuration = touchEndTime - touchStartTime;
        
        if (touchDuration < 200) { // Tempo massimo per considerare un tocco come clic
            //console.log("Tocco eseguito");
            onTouchMissionsUser(event);
        }
    }

    const onTouchMissions = (event) => {
        event.preventDefault();
        //console.log("NUMERO CLICK")
        window.open(props.htkBackend + "/missioni");
      };

      const onTouchMissionsUser = (event) => {
        event.preventDefault();
        //console.log("NUMERO CLICK")
        window.open(props.assetsBase + "/");
      };
        
    useInterval(_userSessionCheck, delay);

    /*
    const closePanel = () => {
        const navbar = document.querySelector('.barraNavigazione');
        navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
        const altezzaNavbar = navbar.offsetHeight;
        navbar.style.marginTop = `-${altezzaNavbar}px`;

        setTimeout(() => {
            const bottone = document.querySelector('.contenitoreIcona2');
            bottone.classList.remove('invisible');
            bottone.classList.add('visible');
        }, 410);
    }

    const openPanel = () => {
        const navbar = document.querySelector('.barraNavigazione');
        navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
        navbar.style.marginTop = '0px';

        const bottone = document.querySelector('.contenitoreIcona2');
        bottone.classList.remove('visible');
        bottone.classList.add('invisible');

        const controlPanel = document.querySelector('.ControlPanelLaterale');
        controlPanel.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
        controlPanel.style.marginTop = '0px';
    }
*/

    const closePanelResp = () => {
        const navbar = document.querySelector('.barraNavigazione');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            const altezzaNavbar = navbar.offsetHeight;
            navbar.style.marginTop = `-${altezzaNavbar}px`;
        }

        setTimeout(() => {
            
            const bottone = document.querySelector('.contenitoreIcona2Resp');
            if(bottone){
                bottone.classList.remove('invisible');
                bottone.classList.add('visible');
            }

        }, 50);
    }


    const openPanelResp = () => {
        const navbar = document.querySelector('.barraNavigazione');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            navbar.style.marginTop = '0px';
        }

        const bottone = document.querySelector('.contenitoreIcona2Resp');
        if(bottone){
            bottone.classList.remove('visible');
            bottone.classList.add('invisible');
        }
        
    }

    const openPanel = () => {
        //console.log("open panel auth.js");
        const navbar = document.querySelector('.barraNavigazione');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            navbar.style.marginTop = '0px';
        }

        const bottone = document.querySelector('.contenitoreIcona2');
        if(bottone){
            bottone.classList.remove('visible');
            bottone.classList.add('invisible');
        }

        const controlPanel = document.querySelector('.ControlPanelLaterale');
        if(controlPanel){
            controlPanel.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            controlPanel.style.marginTop = '0px';
        }
    }
    
    const closePanel = () => {
        //console.log("close panel auth.js");

        const navbar = document.querySelector('.barraNavigazione');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            const altezzaNavbar = navbar.offsetHeight;
            navbar.style.marginTop = `-${altezzaNavbar}px`;
        }

        setTimeout(() => {
            const bottone = document.querySelector('.contenitoreIcona2');
            if(bottone){
                bottone.classList.remove('invisible');
                bottone.classList.add('visible');
            }
        }, 10);
    }

    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };

      function resizeWindow() {
        const resizeEvent = window.document.createEvent("UIEvents");
        resizeEvent.initUIEvent("resize", true, false, window, 0);
        window.dispatchEvent(resizeEvent);
    }

    function timeoutPromise(ms, fallbackValue) {
        return new Promise((_, reject) => {
            setTimeout(() => {
                reject(new Error('Fetch timeout'));
            }, ms);
        }).catch(() => fallbackValue);
    }

    async function cambiaMappa(){

        // Funzione per mostrare o nascondere lo spinner
        function toggleLoadingSpinner(show) {
            var wrapper = document.getElementById('spinnerWrapper');

            if (show) {
                // Mostra lo spinner e il testo
                wrapper.style.opacity = 1;
                wrapper.style.visibility = 'visible';
            } else {
                // Nascondi lo spinner e il testo
                wrapper.style.opacity = 0;
                wrapper.style.visibility = 'hidden';

                const infoBoxWindy = document.querySelector(".FlyInfoPanelContainerWindy");
                if(infoBoxWindy){
                    infoBoxWindy.style.setProperty("display", "block", "important");
                    infoBoxWindy.style.setProperty("display", "flex", "important");
                    infoBoxWindy.style.setProperty("justify-content", "center", "important");
                    infoBoxWindy.style.setProperty("align-items", "center", "important");
                }

            }
        }

        // Esempio di utilizzo
        // Mostra lo spinner e la scritta
        toggleLoadingSpinner(true);

/*      per quanto riguarda la leaflet non serve oscurare l'header leaflet e mostrare l'header windy,
        perchè oscurando la mappa , oscuro automaticamente anche tutti gli oggetti sulla mappa leaflet
*/
        const authHeaderGoogle = document.querySelector(".barraNavigazione");
        authHeaderGoogle.style.display = "none";

        // console.log("Valore switchMappaCount : " , switchMappa3DCount);

        if(switchMappa3DCount < 1){
            // switchMappa3DCount++;
            setSwitchMappaCount3D(switchMappa3DCount + 1);
            // await setMapCenterAndZoom(coord.lat, coord.lng, zoom);
            
            // Nascondi lo spinner dopo 3 secondi (3000 millisecondi)
            setTimeout(function() {
                toggleLoadingSpinner(false);
            }, 4000);
        }
        else{
            // Nascondi lo spinner dopo 3 secondi (3000 millisecondi)
            setTimeout(function() {
                toggleLoadingSpinner(false);
            }, 500);
        }

        var coord = map.getCenter();
        
        var zoom = map.getZoom();

        //console.log("COORD : " , coord);

        //console.log("ZOOM : " , zoom);

        async function calculateRangeFromZoom(zoom, lat, lng) {
            
            //console.log("ZOOM LEAFLET : " + zoom);
             const url = `https://api.open-elevation.com/api/v1/lookup?locations=${lat},${lng}`;
         
             // Definisci il valore di fallback per l'elevazione
             const fallbackElevation = 50;
             const timeoutMs = 500; // 500 ms di timeout
         
             try {
                 // Crea la promessa della fetch
                 const fetchPromise = fetch(url)
                     .then(response => response.json())
                     .then(data => {
                         if (data.results && data.results.length > 0) {
                           //console.log("OK");
                             return data.results[0].elevation;
                         } else {
                           //console.log("NOT OK");
                             return fallbackElevation;
                         }
                     });
         
                 // Crea la promessa del timeout
                 const timeoutPromiseResult = timeoutPromise(timeoutMs, fallbackElevation);
         
                 // Usa Promise.race per risolvere la fetch o il timeout, a seconda di quale accade prima
                 const elevation = await Promise.race([fetchPromise, timeoutPromiseResult]);
         
                //console.log("Elevazione:", elevation);
         
                 // Mappa i livelli di zoom di Leaflet ai range appropriati per Google Maps 3D
                 const zoomToRange = [
                     { zoom: 0, range: 66874361},
                     { zoom: 1, range: 33074361},
                     { zoom: 2, range: 16874361},
                     { zoom: 3, range: 8874361},
                     { zoom: 4, range: 7437177},
                     { zoom: 5, range: 4718585},
                     { zoom: 6, range: 2359289},
                     { zoom: 7, range: 1179641}, // default in caso di errore della fetch
                     { zoom: 8, range: 589817},
                     { zoom: 9, range: 294905},
                     { zoom: 10, range: 147449},
                     { zoom: 11, range: 73721},
                     { zoom: 12, range: 35857},
                     { zoom: 13, range: 18425},
                     { zoom: 14, range: 9209},
                     { zoom: 15, range: 4601},
                     { zoom: 16, range: 2297},
                     { zoom: 17, range: 1145},
                     { zoom: 18, range: 569},
                     { zoom: 19, range: 281},
                     { zoom: 20, range: 137},
                     { zoom: 21, range: 65},
                     { zoom: 22, range: 29}
                 ];
 
                function generateZoomIntervals(zoomToRange) {
                    // console.log("genera intervalli....");
                    const newZoomToRange2 = [];
                    const steps = 10;
                
                    for (let i = 0; i < zoomToRange.length - 1; i++) {
                        const currentZoom = zoomToRange[i];
                        const nextZoom = zoomToRange[i + 1];
                
                        const zoomStep = (nextZoom.zoom - currentZoom.zoom) / steps;
                        const rangeStep = (nextZoom.range - currentZoom.range) / steps;
                
                        for (let j = 0; j < steps; j++) {
                            newZoomToRange2.push({
                                zoom: parseFloat((currentZoom.zoom + zoomStep * j).toFixed(1)),
                                range: parseFloat((currentZoom.range + rangeStep * j).toFixed(1))
                            });
                        }
                    }
                
                    // Add the last item
                    newZoomToRange2.push(zoomToRange[zoomToRange.length - 1]);
                
                    return newZoomToRange2;
                }
                 
                let newZoomToRange = generateZoomIntervals(zoomToRange);
                //  console.log("NEW RANGE : " + JSON.stringify(newZoomToRange));
 
                 function getLeafletRangeFromZoom(zoom) {
                     // Cerca il range corrispondente
                     // for (let i = 0; i < zoomToRange.length; i++) {
                     for (let i = (newZoomToRange.length - 1); i >= 0; i--) {
                         if (zoom >= newZoomToRange[i].zoom) {
                             return newZoomToRange[i].range;
                         }
                     }
                    //  console.log("calcolato : " , newZoomToRange[newZoomToRange.length - 1].range)
                     // Se il range è più piccolo del minimo valore nella mappatura, ritorna il massimo livello di zoom
                     return newZoomToRange[newZoomToRange.length - 1].range;
                 }
         
                 return {
                     //range: zoomToRange[zoom] || 1000, // Ritorna 1000 come default se il livello di zoom non è specificato
                     range: getLeafletRangeFromZoom(zoom) || 1000,
                     elevation: elevation
                 };
             } catch (error) {
                // console.log('Error:', error);
                 return {
                     range: 1179641, // Default range in caso di errore
                     elevation: fallbackElevation
                 };
             }
        }

        async function setMapCenterAndZoom(lat, lng, zoom/*, bearing*/) {
            const mappaEarth = document.querySelector('gmp-map-3d');
            if (mappaEarth) {
                let { range, elevation } = await calculateRangeFromZoom(zoom,lat,lng);
                let altitude = elevation;

               //console.log("range : " + range);

               //console.log("elevation : " + elevation);

                //let altitude = 100;

                //console.log("Altitude:", altitude);
                
                //console.log("COORDINATE (ZOOM) ALTITUDINE CALCOLATA : " + altitudeCalc.toFixed(0));
                //console.log("COORDINATE (ZOOM) RANGE CALCOLATO : " + range.toFixed(0));
                
                mappaEarth.center = { lat, lng, altitude };
                mappaEarth.range = range;
                mappaEarth.tilt = 0;
                //mappaEarth.heading = bearing;
                mappaEarth.heading = 0;
            }
        }

        await setMapCenterAndZoom(coord.lat, coord.lng, zoom);

        // cambio mappa

        const mappaGoogle = document.querySelector(".mappaGoogle");
        mappaGoogle.style.display = "none";

        const mappaWindyStyle = document.getElementById("mappaGoogle3D");
        mappaWindyStyle.style.display = "block";

        //

        const authHeaderWindy = document.querySelector(".barraNavigazioneWindy");
        authHeaderWindy.style.setProperty("display", "block", "important");
        authHeaderWindy.style.width = "100vw";

        const controlPanelWindy = document.querySelector(".ControlPanelWindy");
        if(controlPanelWindy){
            controlPanelWindy.style.setProperty("display", "block", "important");
            controlPanelWindy.style.width = "240px";
            //controlPanelWindy.style.height = "240px";
        }

        //props.mappaWindy.invalidateSize();

        props.setMappaWindyProps();

      };

    if (currentUser) {
        // return (<h1>Benvenuto {currentUser.email}</h1>);

        return (
            <>
                {!isMob  && //isNavbarOpen && Mostra la barra dell'intestazione solo su schermi larghi o in modalità landscape su dispositivi mobili
                    (
                        <>
                        <nav className="navbar navbar-expand-md navbar-light bg-white shadow-sm navBarSystem barraNavigazione" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <div className="d-flex flex-row align-items-center w-100 container position-relative">
                                <div className="">
                                <img src={props.htkBackend + "/brand/QWEB.png"} width="40" alt="Logo" />
                                </div>
                                <div className="ms-auto ">
                                <div className="d-flex flex-row p-2 align-items-center">
                                    {
                                        /*
                                        <button id="mioPulsante" onClick={cambiaMappa}>Wheather</button>
                                        */
                                    }

{/*
                                    <button id="mioPulsante" onClick={toggleGeolocation}>
                                        {isGeolocationEnabled ? 'Disabilita GPS' : 'Abilita GPS'}
                                    </button>
*/}

                                    <img className="" src="/brandIcons/GPS_POSITION.png" style={{ height: '40px', width: '40px' }} alt="Mission Icon" />
                                    <a className="btn btn-gray" target="_blank" onClick={cambiaMappa}>Google 3D</a>

                                    <img className="" src="/brandIcons/GPS_POSITION.png" style={{ height: '40px', width: '40px' }} alt="Mission Icon" />
                                    <a className="btn btn-gray" target="_blank" onClick={toggleGeolocation}>{isGeolocationEnabled ? 'Disabilita GPS' : 'Abilita GPS'}</a>

                                    <img className="" src="/brandIcons/MISSIONI.png" style={{ height: '40px', width: '40px' }} alt="Mission Icon" />
                                    <a className="btn btn-gray" target="_blank" href={props.htkBackend + "/missioni"}>Missions</a>

                                    <img className="" src="/brandIcons/USERS.png" style={{ height: '40px', width: '40px' }} alt="User Icon" />
                                    <a className="btn btn-gray" target="_blank" href={props.assetsBase + "/"}>{currentUser.completeName}</a>
                                </div>
                                </div>
                            </div>
                            <div className={`divContainerButtSeleziona2 position-absolute start-50 translate-middle contenitoreIcona ${isHovered ? 'visible' : 'invisible'}`} style={{ bottom: '0', transform: 'translateX(-31px)' }} onClick={closePanel}>
                                    <div className="fas fa-2x fa-chevron-up iconaFreccia"></div>
                            </div>
                        </nav>
                        <div className="divContainerButtSeleziona2 position-absolute start-50 translate-middle contenitoreIcona2 invisible" style={{top: '0', transform: 'translateX(-16px)' }} onClick={openPanel}>
                            <div className="fas fa-2x fa-chevron-down iconaFreccia2"></div>
                        </div>
                        </>
                    ) 
                }
                

                {isMob && isPortrait && // Mostra la barra dell'intestazione solo su schermi stretti e in modalità portrait su dispositivi mobili
                                (
                                    <>
                                    <nav className="navbar navbar-light bg-white shadow-sm navBarSystemPortrait barraNavigazione">
                                        <div className="d-flex flex-row align-items-center container">

                                                <div className="d-flex flex-row align-items-center text-primary">
                                                    <img src={props.htkBackend + "/brand/QWEB.png"} className="imgQwebHeader1"/>
                                                </div>
                                                
                                                {/*
                                                <div className="d-flex flex-row align-items-center text-primary">
                                                    <button onTouchStart={toggleGeolocation}>
                                                        {isGeolocationEnabled ? 'Disabilita GPS' : 'Abilita GPS'}
                                                    </button>
                                                </div>
                                                */}

                                                <div className="d-flex flex-row align-items-center text-primary">
                                                    <img className="imgQwebHeader" src="/brandIcons/GPS_POSITION.png" />
                                                    <a className="btn btn-gray" target="_blank" onTouchEnd={toggleGeolocation}>{isGeolocationEnabled ? 'Disabilita GPS' : 'Abilita GPS'}</a>
                                                </div>
                                                
                                                <div className="d-flex flex-row align-items-center text-primary">
                                                    <img className="imgQwebHeader" src="/brandIcons/MISSIONI.png"  /> 
                                                    <a className="btn btn-gray" target="_blank" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>Missions</a>
                                                </div>
                                                
                                                <div className="d-flex flex-row align-items-center text-primary">
                                                    <img className="imgQwebHeader" src="/brandIcons/USERS.png" /> 
                                                    <a className="btn btn-gray" target="_blank" onTouchStart={handleTouchStartUser} onTouchEnd={handleTouchEndUser}>{currentUser.completeName}</a>
                                                </div>
                                        </div>
                                        <div className="divContainerButtSeleziona2 position-absolute start-50 translate-middle contenitoreIconaResp dimensioniIconaApri" style={{ bottom: '0', transform: 'translateX(-31px)' }} onTouchEnd={closePanelResp}>
                                            <div className="fas fa-1x fa-chevron-up iconaFrecciaResp"></div>
                                        </div>
                                    </nav>
                                    <div className="divContainerButtSeleziona2 position-absolute start-50 translate-middle contenitoreIcona2Resp invisible dimensioniIconaChiudi" style={{top: '0', transform: 'translateX(-16px)' }} onTouchEnd={openPanelResp}>
                                        <div className="fas fa-1x fa-chevron-down iconaFreccia2Resp"></div>
                                    </div>
                                    </>
                                ) 
                }

            {isMob && !isPortrait /*&& // Mostra la barra dell'intestazione solo su schermi stretti e in modalità portrait su dispositivi mobili
                                (
                                    <>
                                    <nav className="navbar navbar-light bg-white shadow-sm navBarSystemPortrait barraNavigazione">
                                        <div className="d-flex flex-row align-items-center container">

                                                <div className="d-flex flex-row align-items-center text-primary">
                                                    <img src={props.htkBackend + "/brand/QWEB.png"} className="imgQwebHeader1"/>
                                                </div>
                                                
                                                <div className="d-flex flex-row align-items-center text-primary">
                                                    <img className="imgQwebHeader" src="/brandIcons/MISSIONI.png"  /> 
                                                    <a className="btn btn-gray" target="_blank" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>Missions</a>
                                                </div>
                                                
                                                <div className="d-flex flex-row align-items-center text-primary">
                                                    <img className="imgQwebHeader" src="/brandIcons/USERS.png" /> 
                                                    <a className="btn btn-gray" target="_blank" onTouchStart={handleTouchStartUser} onTouchEnd={handleTouchEndUser}>{currentUser.completeName}</a>
                                                </div>
                                        </div>
                                        <div className="divContainerButtSeleziona2 position-absolute start-50 translate-middle contenitoreIconaResp dimensioniIconaApri" style={{ bottom: '0', transform: 'translateX(-31px)' }} onTouchEnd={closePanelResp}>
                                            <div className="fas fa-1x fa-chevron-up iconaFrecciaResp"></div>
                                        </div>
                                    </nav>
                                    <div className="divContainerButtSeleziona2 position-absolute start-50 translate-middle contenitoreIcona2Resp invisible dimensioniIconaChiudi" style={{top: '0', transform: 'translateX(-16px)' }} onTouchEnd={openPanelResp}>
                                        <div className="fas fa-1x fa-chevron-down iconaFreccia2Resp"></div>
                                    </div>
                                    </>
                                ) */
                }

            </>
        )
    } else {
        return (
                <>
                    <nav className="navbar navbar-expand-md navbar-light bg-white shadow-sm navBarSystem">
                        <div className="d-flex flex-row align-items-center w-100 container">
                            <div className=""><img src={props.assetsBase + "/brand/QWEB.png"} width="40" /></div>
                            <div className="ms-auto d-flex flex-row">
                                <h5>Per utilizzare q-live è necessario autenticarsi</h5>
                                <button type="button" onClick={loginLaunch}>
                                    Autenticami
                                </button>
                            </div>
                        </div>
                    </nav>
                </>
        )
    }
}