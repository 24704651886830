import React, {useRef, useState, useEffect} from "react";

export default function InfoBoxTitleResponsive(props) {
    const [pdv, setPdv] = useState(props.pdv);
    const [userTipo, setUserTipo] = useState(props.userTipo)

    useEffect(() => {
        setPdv(props.pdv);
        setUserTipo(props.userTipo);
    }, [props.pdv, props.userTipo])

    const _fnTitleInfoBox = () => {
        switch (userTipo) {
            case 1:
                return (
                    <h4>{pdv?.alias}</h4>
                )
            case 2:
                return (
                    <div>{pdv?.seriale.seriale}/{pdv?.marca}/{pdv?.alias}</div>
                )
            case 3:
                return (
                    <h4>{pdv?.marca}</h4>
                )
            default:
                return (
                    <h4>Loading ...</h4>
                )
        }
    } ;

    const _fnTitleInfoBox2 = () => {
        switch (userTipo) {
            case 1:
                return (
                    <div>{pdv?.alias}</div>
                )
            case 2:
                return (
                    <div>{pdv?.alias}</div>
                )
            case 3:
                return (
                    <div>{pdv?.marca}</div>
                )
            default:
                return (
                    <div>Loading ...</div>
                )
        }
    } ;

    return (
            <div className="d-flex align-items-center mr-auto divInfoTitle">
                {/*
                <div className="mr-1 img-logo-mini">
                    { pdv ? (<img src={props.htkBackend + "/storage/" + pdv?.iconaTipoDispositivo} width="12" />) : (<div />) }
                </div>
                */}
                {_fnTitleInfoBox2()}
            </div>
        );
}