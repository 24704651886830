import React, { useRef, useState, useEffect } from 'react';
import Hls from 'hls.js';

const VideoPlayerHls = ({ videoStreaming, nomeDispositivo, videoStreamingName, isDrone }) => {
  const videoRef = useRef(null);
  const [videoSrc, setVideoSrc] = useState('');
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const [isPictureInPictureActive, setIsPictureInPictureActive] = useState(false);

  useEffect(() => {
    const updateVideoSrc = () => {
      const newVideoSrc = isDrone
        ? `${videoStreaming}/${nomeDispositivo}/index.m3u8`
        : `${videoStreaming}/${nomeDispositivo}/${videoStreamingName}/index.m3u8`;
      setVideoSrc(newVideoSrc);
    };

    updateVideoSrc();
  }, [videoStreaming, nomeDispositivo, videoStreamingName, isDrone]);

  useEffect(() => {
    let hls = null;

    const initializeHls = () => {
      if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(videoSrc);
        hls.attachMedia(videoRef.current);

        hls.on(Hls.Events.ERROR, function (event, data) {
          if (data.fatal) {
            console.error('Errore fatale durante il caricamento del video:', data.type, data.details);
            
            if (data.type !== Hls.ErrorTypes.NETWORK_ERROR || data.details !== Hls.ErrorDetails.MANIFEST_PARSING_ERROR) {
              setTimeout(() => setReloadTrigger(prevState => !prevState), 5000); // Timeout di 5 secondi per il reload
            }
          }
        });

        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          if (videoRef.current) {
            videoRef.current.addEventListener('loadedmetadata', () => {
              playVideo();
            });
          }
        });
      } else {
        console.error('Questo browser non supporta HLS');
      }
    };

    const playVideo = () => {
      if (videoRef.current) {
        const playPromise = videoRef.current.play();
        if (playPromise !== undefined) {
          playPromise.then(() => {
            if (isPictureInPictureActive) {
              videoRef.current.requestPictureInPicture().catch(error => {
                console.error('Errore durante la riattivazione del Picture-in-Picture:', error);
              });
            }
          }).catch(error => {
            console.error('Errore durante la riproduzione automatica:', error);
          });
        }
      }
    };

    const handleLeavePictureInPicture = () => {
      setIsPictureInPictureActive(false);
      setTimeout(playVideo, 200); // Attendi un attimo prima di riprovare a riprodurre
    };

    const handleEnterPictureInPicture = () => {
      setIsPictureInPictureActive(true);
    };

    initializeHls();

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('leavepictureinpicture', handleLeavePictureInPicture);
      videoElement.addEventListener('enterpictureinpicture', handleEnterPictureInPicture);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('leavepictureinpicture', handleLeavePictureInPicture);
        videoElement.removeEventListener('enterpictureinpicture', handleEnterPictureInPicture);
      }
      if (hls) {
        hls.destroy();
      }
    };
  }, [videoSrc, reloadTrigger, isPictureInPictureActive]);

  return (
    <video
      autoPlay
      controls
      ref={videoRef}
      className="custom-video-player-pc"
      key={reloadTrigger}
    ></video>
  );
};

export default VideoPlayerHls;