import React from "react";
import ConnectionState from "./ConnectionState";
import Telemetria from "./telemetria";
import Media from './Media';
import {useState, useEffect, useRef, useCallback} from "react";
import {useMap} from "react-leaflet";
import "../InfoBox.css";
import Moment from 'react-moment';
import 'moment/locale/it';
import useInterval from "../hooks/useInterval";
import axios from 'axios';
import Infoboxtitle from "./infoboxtitle";
import BigMedia from "./bigMedia";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import VideoPlayerHls from "./VideoPlayerHls";

export default function InfoBoxWindy(props) {

    // const { toggleTrackVisibility } = props;

    const [isWindowVisible, setIsWindowVisible] = useState(true);
    const [lastActiveButton, setLastActiveButton] = useState(null);

    const isPrimaActive = props.infoboxStates[props.ALIAS];
    const isTerzaActive = props.infoboxStatesSimu[props.ALIAS];

    const [isTrackVisible, setTrackVisible] = useState(true);

    const [infoBoxId, setInfoboxId] = useState(props.index);
    const videoPlayerKey = props.videoPlayerKey || 0; // Chiave unica per forzare il re-render della componente VideoPlayerStreamingTest

    //const [videoStreamingContainer, setVideoStreamingContainer] = useState("videoStreamingContainer");
    const [videoStreamingH265, setVideoStreamingH265] = useState(props.urlVideoStreamingH265);
    const [videoStreamingH264, setVideoStreamingH264] = useState(props.urlVideoStreamingH264);

    const [node, setNode] = useState(props.singleSnData);

    const [allNode, setAllNode] = useState(props.allSnData);
    const [prevAllNode, setPrevAllNode] = useState(allNode);

    const mainPolylineRef = useRef(null); // Riferimento alla polilinea principale
    const pointPolylinesRef = useRef([]); // Riferimento alle piccole polylines dei punti GPS

    const [videoStreamingName, setVideoStreamingName] = useState(node?.point?.info?.MISSION_NUM);

    const [imagePoint, setImagePoint] = useState(null);
    const [isToggled, setToggled] = useState(false);
    const [iconLinkStatus, setIconLinkStatus] = useState("fa-chevron-down");
    const [classStatePanel, setClassStatePanel] = useState("");

    const [linkStatus, changeLinkStatus] = useState("SEARCHING");
    const [linkStatusStreaming, changeLinkStatusStreaming] = useState("SEARCHING");
    const [pdv, setPdv] = useState(null);
    const [allData, setAllData] = useState(props.allData);
    const [seriale, setSeriale] = useState("Seriali ...");
    const serialiStreaming = props.serialiStreaming;
    const streamingH265 = props.streamingH265;
    const streamingH264 = props.streamingH264;

    const streamingDrone = props.streamingDrone;

    const [imageData, setImageData] = useState(null);
    const [lastFlyToPosition, setLastFlyToPosition] = useState();
    const [userTipo, setUserTipo] = useState(props.userTipo);
    const [bigMediaPic, setBigMediaPic] = useState(null);
    const [typeBigImage, setTypeBigImage] = useState("IR_IMAGE");
    const [typeImage, setTypeImage] = useState("IR_IMAGE");
    const [videoOpen, setVideoOpen] = useState(false);
    const [photoOpen, setPhotoOpen] = useState(false);

    const [gradiLat, setGradiLat] = useState(node.point.points.LATITUDE);
    const [minutiLat, setMinutiLat] = useState(node.point.points.LATITUDE);
    const [secondiLat, setSecondiLat] = useState(node.point.points.LATITUDE);

    const [gradiLon, setGradiLon] = useState(node.point.points.LONGITUDE);
    const [minutiLon, setMinutiLon] = useState(node.point.points.LONGITUDE);
    const [secondiLon, setSecondiLon] = useState(node.point.points.LONGITUDE);

    const [infoboxStatesStreaming, setInfoboxStatesStreaming] = useState(props.infoboxStatesStreaming);

    const [prevHeading, setPrevHeading] = useState(null);

    const [prevLat, setPrevLat] = useState(null);
    const [prevLon, setPrevLon] = useState(null);
    const [prevAlt, setPrevAlt] = useState(null);
    const [prevPKTNUM, setPrevPKTNUM] = useState(null);

    const [rilevazioni, setRilevazioni] = useState(props.rilevazioni);

    const polylinesRef = useRef([]); // Ref per le polylines
    const pointsRef = useRef([]); // Ref per i punti

    const [isAnimating, setIsAnimating] = useState(false); // Stato per controllare l'animazione

    const [range, setRange] = useState(1000); // Stato per controllare l'animazione

    const animationFrameId = useRef(null); // Per salvare l'ID dell'animazione corrente

    const [currentAnimationFrameId, setCurrentAnimationFrameId] = useState(null);
    const createdElementsRef = useRef([]); // Memorizza gli elementi creati

    //const map = useMap();

    const [convertedAngle, setConvertedAngle] = useState(null);

    // const [isWindowVisible, setIsWindowVisible] = useState(true);

    const [buttonPrima, setButtonPrima] = useState(true);

    const [buttonTerza, setButtonTerza] = useState(true);

    const flyZoomOptions = {
        animate: true, duration: 0.5
    }

    const apiClient = axios.create({
        baseURL: props.htkBackend,
        withCredentials: true,
    })

    const cancelCurrentAnimation = () => {
        if (currentAnimationFrameId !== null) {
            cancelAnimationFrame(currentAnimationFrameId);
            setCurrentAnimationFrameId(null);  // Resetta lo stato dell'ID dell'animazione
        }
    
        // Cancella anche le polylines e i punti animati
        if (createdElementsRef.current) {
            createdElementsRef.current.forEach(el => {
                if (el && el.remove) {
                    el.remove();  // Rimuovi ogni polyline/elemento animato dalla mappa
                }
            });
            createdElementsRef.current = []; // Resetta l'array
        }
    };    

    useEffect(() => {
        setRilevazioni(props.rilevazioni);
      }, [props.rilevazioni]);

    useEffect(() => {
        setInfoboxId(infoBoxId);
      }, [infoBoxId]);

      useEffect(() => {
        setInfoboxStatesStreaming(props.infoboxStatesStreaming);
      }, [props.infoboxStatesStreaming]);

    useEffect(() => {
        if (linkStatusStreaming !== 'CONNECTED') {
          //setVideoOpen(false);
          props.setInfoboxStatesStreaming(prevStates => ({
            ...prevStates,
            [props.ALIAS]: false,
          }));
        }
      }, [linkStatusStreaming]);
    
    useEffect(() => {
        const iframe = document.getElementById('myIframe');
    
        if (iframe) {
          iframe.addEventListener('load', () => {
            const video = iframe.contentDocument.querySelector('video');
            if (video) {
              video.style.fontSize = '20px';
            }
          });
        }
      }, []);
    

    useEffect(() => {
        setUserTipo(props.userTipo);
    }, [props.userTipo]);

    useEffect(() => {
        setVideoStreamingName(node.point.info.MISSION_NUM);
      }, [node.point.info.MISSION_NUM]);

      /*
      useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                setIsWindowVisible(false);
    
                if (props.infoboxStates[props.ALIAS]) {
                   //console.log("era premuto follow prima -", new Date().toLocaleTimeString());
                    setButtonPrima(true);
                    setButtonTerza(false);
                    props.toggleFollowFalse(props.ALIAS); // Disattiva FollowPrima
                }
    
                if (props.infoboxStatesSimu[props.ALIAS]) {
                   //console.log("era premuto follow terza -", new Date().toLocaleTimeString());
                    setButtonPrima(false);
                    setButtonTerza(true);
                    props.toggleFollowFalseSimu(props.ALIAS); // Disattiva FollowTerza
                }

                if(!props.infoboxStates[props.ALIAS] && !props.infoboxStatesSimu[props.ALIAS]){
                   //console.log("non era premuto nulla-", new Date().toLocaleTimeString());
                    setButtonPrima(false);
                    setButtonTerza(false);
                }
    
            } else {
                setIsWindowVisible(true);
    
                // Controlla lo stato dei bottoni quando si torna alla finestra
                if (buttonPrima) {
                   //console.log("era stato premuto follow prima -", new Date().toLocaleTimeString());
                    setTimeout(() => {
                        props.toggleFollow(props.ALIAS); // Riattiva FollowPrima
                    }, 1000);
                }
    
                if (buttonTerza) {
                   //console.log("era stato premuto follow terza -", new Date().toLocaleTimeString());
                    setTimeout(() => {
                        props.toggleFollowSimu(props.ALIAS); // Riattiva FollowTerza
                    }, 1000);
                }

                if(!buttonPrima && !buttonTerza){
                   //console.log("non era premuto nulla-", new Date().toLocaleTimeString());
                }

            }
    
            // Cancella eventuali animazioni in corso se la finestra è nascosta
            if (document.hidden && createdElementsRef.current) {
                cancelCurrentAnimation(); // Cancella eventuali animazioni
            }
        };
    
        document.addEventListener('visibilitychange', handleVisibilityChange);
    
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [allNode]);
*/

    const handleVisibilityChange = useCallback(() => {
        if (document.hidden) {
            setIsWindowVisible(false);
            if (isPrimaActive) {
                setLastActiveButton('prima');
                props.toggleFollowFalse(props.ALIAS);
            } else if (isTerzaActive) {
                setLastActiveButton('terza');
                props.toggleFollowFalseSimu(props.ALIAS);
            } else {
                setLastActiveButton(null);
            }
        } else {
            setIsWindowVisible(true);
            setTimeout(() => {
                if (lastActiveButton === 'prima') {
                    props.toggleFollow(props.ALIAS);
                } else if (lastActiveButton === 'terza') {
                    props.toggleFollowSimu(props.ALIAS);
                }
            }, 1000);
        }
    }, [props, isPrimaActive, isTerzaActive, lastActiveButton]);

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [handleVisibilityChange]);

    const handlePrimaClick = () => {
        if (isPrimaActive) {
            props.toggleFollowFalse(props.ALIAS);
            setLastActiveButton(null);
        } else {
            props.toggleFollow(props.ALIAS);
            props.toggleFollowFalseSimu(props.ALIAS);
            setLastActiveButton('prima');
        }
        props.toggleTrackVisibility();
    };

    const handleTerzaClick = () => {
        if (isTerzaActive) {
            props.toggleFollowFalseSimu(props.ALIAS);
            setLastActiveButton(null);
        } else {
            props.toggleFollowSimu(props.ALIAS);
            props.toggleFollowFalse(props.ALIAS);
            setLastActiveButton('terza');
        }
    };

    const isAnyOtherActive = Object.entries(props.infoboxStates).some(
        ([key, value]) => key !== props.ALIAS && value === true
    ) || Object.entries(props.infoboxStatesSimu).some(
        ([key, value]) => key !== props.ALIAS && value === true
    );
    

    // Dichiarazione dello stato per memorizzare l'attivazione dei bottoni
// const [wasFollowPrimaActive, setWasFollowPrimaActive] = useState(false);
// const [wasFollowTerzaActive, setWasFollowTerzaActive] = useState(false);

// useEffect(() => {
//     const handleVisibilityChange = () => {
//         if (document.hidden) {
//             // Finestra nascosta: memorizza lo stato attuale
//             setIsWindowVisible(false);

//             if (props.infoboxStates[props.ALIAS]) {
//                 console.log("era premuto follow prima -", new Date().toLocaleTimeString());
//                 setWasFollowPrimaActive(true);  // Memorizza stato attivo di FollowPrima
//                 setButtonPrima(true);
//                 setButtonTerza(false);
//                 props.toggleFollowFalse(props.ALIAS);  // Disattiva FollowPrima
//             } else {
//                 setWasFollowPrimaActive(false);
//             }

//             if (props.infoboxStatesSimu[props.ALIAS]) {
//                 console.log("era premuto follow terza -", new Date().toLocaleTimeString());
//                 setWasFollowTerzaActive(true);  // Memorizza stato attivo di FollowTerza
//                 setButtonPrima(false);
//                 setButtonTerza(true);
//                 props.toggleFollowFalseSimu(props.ALIAS);  // Disattiva FollowTerza
//             } else {
//                 setWasFollowTerzaActive(false);
//             }

//             if (!props.infoboxStates[props.ALIAS] && !props.infoboxStatesSimu[props.ALIAS]) {
//                 console.log("non era premuto nulla-", new Date().toLocaleTimeString());
//                 setButtonPrima(false);
//                 setButtonTerza(false);
//             }

//             // Riattiva temporaneamente tutti gli altri bottoni degli altri infobox
//             Object.keys(props.infoboxStates).forEach(alias => {
//                 if (alias !== props.ALIAS) {
//                     props.toggleFollowTrue(alias);
//                     props.toggleFollowTrueSimu(alias);
//                 }
//             });

//         } else {
//             // Finestra visibile: ripristina lo stato memorizzato
//             setIsWindowVisible(true);

//             if (wasFollowPrimaActive) {
//                 console.log("era stato premuto follow prima -", new Date().toLocaleTimeString());
//                 setTimeout(() => {
//                     props.toggleFollow(props.ALIAS);  // Riattiva FollowPrima
//                 }, 1000);
//             }

//             if (wasFollowTerzaActive) {
//                 console.log("era stato premuto follow terza -", new Date().toLocaleTimeString());
//                 setTimeout(() => {
//                     props.toggleFollowSimu(props.ALIAS);  // Riattiva FollowTerza
//                 }, 1000);
//             }

//             if (!wasFollowPrimaActive && !wasFollowTerzaActive) {
//                 console.log("non era stato premuto nulla-", new Date().toLocaleTimeString());
//             }

//             // Disattiva nuovamente i bottoni Follow degli altri infobox
//             Object.keys(props.infoboxStates).forEach(alias => {
//                 if (alias !== props.ALIAS) {
//                     props.toggleFollowFalse(alias);
//                     props.toggleFollowFalseSimu(alias);
//                 }
//             });
//         }

//         // Cancella eventuali animazioni in corso se la finestra è nascosta
//         if (document.hidden && createdElementsRef.current) {
//             cancelCurrentAnimation();  // Cancella eventuali animazioni
//         }
//     };

//     document.addEventListener('visibilitychange', handleVisibilityChange);

//     return () => {
//         document.removeEventListener('visibilitychange', handleVisibilityChange);
//     };
// }, [props.infoboxStates, props.infoboxStatesSimu, props.ALIAS, wasFollowPrimaActive, wasFollowTerzaActive, buttonPrima, buttonTerza, allNode]);

          
    
    
    /*
    useEffect(() => {
    }, [props.allData])
    */

    var moment = require('moment-timezone');
    //const dateTimeStamp = moment.utc(node.point.time.YEAR + "-" + node.point.time.MONTH + "-" + node.point.time.DAY + " " + node.point.time.HOUR + ":" + node.point.time.MINUTE + ":" + node.point.time.SECOND).local();
    const dateTimeStamp = moment.utc(`${node.point.time.YEAR}-${node.point.time.MONTH}-${node.point.time.DAY} ${node.point.time.HOUR}:${node.point.time.MINUTE}:${node.point.time.SECOND}`, 'YYYY-MM-DD HH:mm:ss').local();

    const toggleTrueFalse = () => {
        setToggled(!isToggled);
        isToggled ? setClassStatePanel("") : setClassStatePanel("InfoBox-Close");
        
        if (isToggled) {
            setClassStatePanel("");
            setTimeout(() => {
                setIconLinkStatus("fa-chevron-down");
            }, 600);
        } else {
            setClassStatePanel("InfoBox-Close");
            setTimeout(() => {
                setIconLinkStatus("fa-chevron-up");
            }, 1100);
        }
    
    };

    const tooglePhotoPanel = () => {
        setPhotoOpen(!photoOpen);

    }

    useInterval(() => {checkPDV(node.SN)}, 1000);

    const checkPDV = async(sn) => {

        apiClient.get(props.htkBackend + "/datalive/PDV/" + sn)
            .then((response) => {
                if (response.status == 200) {
                    if ( response.data.status == "noPDV") {

                    }  else {
                        setPdv(response.data);
                        setSeriale(response.data.seriale.seriale);
                    }

                }
            })
    };

    function normalizeAngle(angle) {
        return (angle % 360 + 360) % 360;
    }
    
    function calculateDeltaHeading(startHeading, heading) {
        const normalizedStartHeading = normalizeAngle(startHeading);
        const normalizedHeading = normalizeAngle(heading);

        let deltaHeading = normalizedHeading - normalizedStartHeading;
        if (deltaHeading > 180) deltaHeading -= 360;
        if (deltaHeading < -180) deltaHeading += 360;
    
        return deltaHeading;
    }    

    const flyToNavigatorPrima = (lat, lng, altitude, heading) => {
        const mappaEarth = document.querySelector('gmp-map-3d');
        if (!mappaEarth) return;
    
        if (createdElementsRef) {
            if (createdElementsRef.current) {
                cancelCurrentAnimation();
            }
        }
    
        // Ottieni la posizione attuale della mappa e altri parametri
        const startLat = mappaEarth.center.lat;
        const startLng = mappaEarth.center.lng;
        const startZoom = mappaEarth.range;
        const startHeading = mappaEarth.heading || 0;
        const startAltitude = mappaEarth.center.altitude;
        const duration = 1500; // Durata dell'animazione in millisecondi
    
        let startTime = null;
        let frameCounter = 0; // Per controllare l'aggiornamento del DOM ogni 2 frame
    
        // Calcolo della differenza dell'heading solo se è diverso da null
        const deltaHeading = heading !== null ? calculateDeltaHeading(startHeading, heading) : null;
    
        const animate = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const progress = Math.min(elapsed / duration, 1);
    
            // Interpolazione per latitudine, longitudine, altitudine e zoom
            const currentLat = startLat + (lat - startLat) * progress;
            const currentLng = startLng + (lng - startLng) * progress;
            const currentRange = startZoom * (1 - progress); // Riduzione graduale dello zoom
            const currentAltitude = startAltitude + (altitude - startAltitude) * progress;
    
            // Aggiorna il centro della mappa e l'altitudine solo ogni 2 frame per ottimizzare le manipolazioni del DOM
            if (frameCounter % 2 === 0) {
                mappaEarth.center = { lat: currentLat, lng: currentLng, altitude: currentAltitude };
                mappaEarth.range = currentRange;
                mappaEarth.tilt = 90;
            }
    
            // Aggiorna l'heading solo se è stato passato come parametro
            if (deltaHeading !== null && frameCounter % 2 === 0) {
                const currentHeading = startHeading + deltaHeading * progress;
                mappaEarth.heading = currentHeading;
            }
    
            frameCounter++;
    
            if (progress < 1) {
                const animationFrameId = requestAnimationFrame(animate);
                setCurrentAnimationFrameId(animationFrameId); // Aggiorna l'ID dell'animazione corrente
            }
        };
    
        const animationFrameId = requestAnimationFrame(animate);
        setCurrentAnimationFrameId(animationFrameId); // Salva l'ID dell'animazione
    };    

    // IMPORTANTE : CAMBIARE LOGICA IN MODO CHE SE ARRIVA PIU DI UN PUNTO NELLO STESSO ISTANTE, INVECE DI EFFETTUARE ANIMAZIONE FINO A TALE PUNTO, VENGA ANIMATO ANCHE IL PUNTO "PERSO DALL'ANIMAZIONE"
    const flyToNavigatorTerza = (lat, lng, altitude, heading, onComplete) => {

       //console.log("FLY TO TERZA");
        
        updatePointPolylines();
        updateMainPolyline();

        const mappaEarth = document.querySelector('gmp-map-3d');
        if (!mappaEarth) return;
    
        if (createdElementsRef) {
            if (createdElementsRef.current) {
                cancelCurrentAnimation(); // Cancella l'animazione in corso
            }
        }
    
        const startLat = mappaEarth.center.lat;
        const startLng = mappaEarth.center.lng;
        const startLatPolyline = prevLat;
        const startLngPolyline = prevLon;
        const startHeading = mappaEarth.heading || 0;
        const startAltitude = prevAlt;
        const duration = 500;
        let startTime = null;
    
        // Calcolo della differenza dell'heading solo se è diverso da null
        const deltaHeading = heading !== null ? calculateDeltaHeading(startHeading, heading) : null;
    
        const segmentCount = 50;
        const polylineCoordinates = [];
    
        for (let i = 0; i <= segmentCount; i++) {
            const progress = i / segmentCount;
            const segmentLat = startLatPolyline + (lat - startLatPolyline) * progress;
            const segmentLng = startLngPolyline + (lng - startLngPolyline) * progress;
            const segmentAltitude = startAltitude + (altitude - startAltitude) * progress;
            polylineCoordinates.push({ lat: segmentLat, lng: segmentLng, altitude: segmentAltitude });
        }
    
        let animatedPolyline = createPolyline(mappaEarth, polylineCoordinates);
        let animatedPoint = createPointPolyline(mappaEarth, { LATITUDE: startLatPolyline, LONGITUDE: startLngPolyline, ALTITUDE: startAltitude });
    
        // Memorizza i riferimenti agli elementi creati per rimuoverli successivamente
        createdElementsRef.current.push(animatedPolyline, animatedPoint);
    
        const animate = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const progress = Math.min(elapsed / duration, 1);
    
            const currentLat = startLat + (lat - startLat) * progress;
            const currentLng = startLng + (lng - startLng) * progress;
            const currentAltitude = startAltitude + (altitude - startAltitude) * progress;
    
            // Aggiorna l'heading solo se non è null
            const currentHeading = deltaHeading !== null
                ? startHeading + deltaHeading * progress
                : startHeading;
    
            // Aggiorna il centro della mappa
            mappaEarth.center = { lat: currentLat, lng: currentLng, altitude: currentAltitude };
    
            // Aggiorna l'heading della mappa solo se il parametro heading non è null
            if (deltaHeading !== null) {
                mappaEarth.heading = currentHeading;
            }
    
            const visibleSegmentIndex = Math.floor(progress * segmentCount);
    
            if (visibleSegmentIndex >= 0 && polylineCoordinates.length > 0) {
                animatedPolyline.coordinates = polylineCoordinates.slice(0, visibleSegmentIndex + 1);
            }
    
            if (visibleSegmentIndex < polylineCoordinates.length) {
                const currentPoint = polylineCoordinates[visibleSegmentIndex];
                updatePointPosition(animatedPoint, currentPoint);
            }
    
            if (progress < 1) {
                const animationFrameId = requestAnimationFrame(animate);
                setCurrentAnimationFrameId(animationFrameId); // Aggiorna l'ID dell'animazione corrente
            } else {
                // Una volta completata l'animazione, rimuovi la polyline e il punto animato
                    setTimeout(() => {
                        if (mappaEarth.contains(animatedPolyline)) {
                            mappaEarth.removeChild(animatedPolyline);
                        }

                    // setTimeout(() => {
                        if (mappaEarth.contains(animatedPoint)) {
                            mappaEarth.removeChild(animatedPoint);
                        }
                    }, 1000);
    
                    // Callback per aggiornare la polyline e i punti solo dopo l'animazione
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
    
                // }, 600); // Ritardo prima di rimuovere la polyline
                setCurrentAnimationFrameId(null); // Animazione completata
            }
        };
    
        const animationFrameId = requestAnimationFrame(animate);
        setCurrentAnimationFrameId(animationFrameId); // Salva l'ID dell'animazione
    };    

    const flyToNavigatorTerzaMultiplePoints = (newPoints, onComplete) => {

       //console.log("FLY TO TERZA MULTIPLE");

        updateMainPolylineMultiple(newPoints.length);
        updatePointPolylinesMultiple(newPoints.length);
    
        if (createdElementsRef) {
            if (createdElementsRef.current) {
                cancelCurrentAnimation(); // Cancella l'animazione in corso
            }
        }
    
        const mappaEarth = document.querySelector('gmp-map-3d');
        if (!mappaEarth || newPoints.length === 0) return;
    
        const startLat = prevLat;
        const startLng = prevLon;
        const startAlt = prevAlt;
        const startHeading = mappaEarth.heading || 0;
    
        const totalDuration = 500; // Durata totale dell'animazione
        const segmentDuration = totalDuration / newPoints.length; // Durata per ogni segmento di animazione
        let startTime = null;
        let currentIndex = 0;
    
        // Recupera o crea la polyline se non esiste ancora
        let animatedPolyline = createdElementsRef.current.find(el => el.tagName === 'GMP-POLYLINE-3D');
        if (!animatedPolyline) {
            animatedPolyline = createPolyline(mappaEarth, []); // Crea una nuova polyline con coordinate inizializzate
            createdElementsRef.current.push(animatedPolyline);
        }
    
        // Inizializza le coordinate con almeno un punto per evitare un array vuoto
        if (!Array.isArray(animatedPolyline.coordinates) || animatedPolyline.coordinates.length === 0) {
            animatedPolyline.coordinates = [{ lat: startLat, lng: startLng, altitude: startAlt }]; // Inizia con la posizione attuale
        }
    
        const polylineCoordinates = [...animatedPolyline.coordinates]; // Mantieni i punti esistenti nella polyline
        const targetPoints = newPoints.map(point => {
            return {
                lat: point.point.points.LATITUDE,
                lng: point.point.points.LONGITUDE,
                altitude: point.point.points.ALTITUDE,
                heading: point.Direction,
            };
        });
    
        // Crea il punto mobile (polyline per il punto animato)
        let animatedPoint = createdElementsRef.current.find(el => el.tagName === 'GMP-POLYLINE-3D-POINT');
        if (!animatedPoint) {
            animatedPoint = createPointPolyline(mappaEarth, { LATITUDE: startLat, LONGITUDE: startLng, ALTITUDE: startAlt });
            createdElementsRef.current.push(animatedPoint);
        }
    
        // Funzione per creare un punto polilinea intermedio
        const createIntermediatePoint = (lat, lng, altitude) => {
            const polylinePoint = document.createElement('gmp-polyline-3d');
            polylinePoint.className = 'custom-polyline';
            polylinePoint.setAttribute('altitude-mode', 'absolute');
            polylinePoint.setAttribute('stroke-color', 'white');
            polylinePoint.setAttribute('stroke-width', '15');
            polylinePoint.coordinates = [
                { lat, lng, altitude },
                { lat: lat + 0.000001, lng, altitude },
                { lat, lng: lng + 0.000001, altitude },
                { lat: lat - 0.000001, lng, altitude },
                { lat, lng: lng - 0.000001, altitude },
                { lat, lng, altitude },
            ];
    
            mappaEarth.appendChild(polylinePoint);
            createdElementsRef.current.push(polylinePoint);
            return polylinePoint;
        };
    
        // Funzione per animare tra due punti
        const animateSegment = (startLat, startLng, startAlt, startHeading, targetLat, targetLng, targetAlt, targetHeading, segmentIndex) => {
    
            // Calcola la differenza di latitudine e longitudine
            const diffLat = Math.abs(targetLat - startLat);
            const diffLng = Math.abs(targetLng - startLng);
    
            // Controlla se la differenza è poca
            const isDistanceSmall = diffLat <= 0.0001 || diffLng <= 0.0001;

            // console.log("IS DISTANCE SMALL ? " , isDistanceSmall);
    
            const deltaHeading = !isDistanceSmall && targetHeading !== null
                ? calculateDeltaHeading(startHeading, targetHeading)
                : null; // Ignora l'animazione dell'heading se la distanza è piccola
    
            const animate = (timestamp) => {
                if (!startTime) startTime = timestamp;
                const elapsed = timestamp - startTime;
                const progress = Math.min(elapsed / segmentDuration, 1);
    
                const currentLat = startLat + (targetLat - startLat) * progress;
                const currentLng = startLng + (targetLng - startLng) * progress;
                const currentAlt = startAlt + (targetAlt - startAlt) * progress;
                const currentHeading = deltaHeading !== null
                    ? startHeading + deltaHeading * progress
                    : startHeading;
    
                // Aggiorna il centro della mappa
                mappaEarth.center = { lat: currentLat, lng: currentLng, altitude: currentAlt };
    
                // Aggiorna l'heading della mappa solo se il parametro heading è valido e la distanza è sufficiente
                if (deltaHeading !== null) {
                    mappaEarth.heading = currentHeading;
                }
    
                // Aggiungi progressivamente il punto alla polyline
                if (progress > 0) {
                    polylineCoordinates.push({ lat: currentLat, lng: currentLng, altitude: currentAlt });
                    animatedPolyline.coordinates = polylineCoordinates; // Aggiorna la polyline con i nuovi punti
                }
    
                // Aggiorna la posizione del punto mobile
                updatePointPosition(animatedPoint, { lat: currentLat, lng: currentLng, altitude: currentAlt });
    
                if (progress < 1) {
                    requestAnimationFrame(animate);
                } else {
                    // Crea il punto polilinea intermedio una volta completato ogni segmento
                    const intermediatePoint = createIntermediatePoint(targetLat, targetLng, targetAlt);
    
                    // Completato il segmento, passa al successivo
                    if (segmentIndex < targetPoints.length - 1) {
                        // Prepara l'animazione per il prossimo segmento
                        startTime = null; // Resetta il tempo per il nuovo segmento
                        currentIndex++;
                        const nextPoint = targetPoints[currentIndex];
                        animateSegment(
                            currentLat, currentLng, currentAlt, currentHeading,
                            nextPoint.lat, nextPoint.lng, nextPoint.altitude, nextPoint.heading,
                            currentIndex
                        );
                    } else {
                        // Una volta completata l'animazione, cancella i punti animati
                        setTimeout(() => {
                            createdElementsRef.current = createdElementsRef.current.filter(el => el !== intermediatePoint);
                            intermediatePoint.remove();
                        }, 1000);
    
                        // Animazione completata su tutti i punti
                        if (typeof onComplete === 'function') {
                            onComplete();
                        }
                    }
                }
            };
    
            requestAnimationFrame(animate);
        };
    
        // Inizia l'animazione dal punto attuale al primo punto della lista
        const firstTargetPoint = targetPoints[0];
        animateSegment(
            startLat, startLng, startAlt, startHeading,
            firstTargetPoint.lat, firstTargetPoint.lng, firstTargetPoint.altitude, firstTargetPoint.heading,
            currentIndex
        );
    };    

    // Funzione per creare e configurare una polyline
    function createPolyline(mappaEarth, polylineCoordinates) {
        const polyline = document.createElement('gmp-polyline-3d');
        polyline.className = 'custom-polyline';
        polyline.setAttribute('altitude-mode', 'absolute');
        polyline.setAttribute('stroke-color', 'red'); // Colore della polyline
        polyline.setAttribute('stroke-width', '10'); // Larghezza della polyline
        mappaEarth.appendChild(polyline);
        return polyline;
    }
    
    // Funzione per creare un punto usando una polyline, basandosi sulla logica fornita
    function createPointPolyline(mappaEarth, punto) {
        if(punto.LATITUDE != null && punto.LONGITUDE != null && punto.ALTITUDE != null){
            const distance = 0.000001;
            const north = { lat: punto.LATITUDE + distance, lng: punto.LONGITUDE, altitude: punto.ALTITUDE };
            const east = { lat: punto.LATITUDE, lng: punto.LONGITUDE + distance, altitude: punto.ALTITUDE };
            const south = { lat: punto.LATITUDE - distance, lng: punto.LONGITUDE, altitude: punto.ALTITUDE };
            const west = { lat: punto.LATITUDE, lng: punto.LONGITUDE - distance, altitude: punto.ALTITUDE };
        
            // Crea la polilinea per simulare il punto
            const polylinePoint = document.createElement('gmp-polyline-3d');
            polylinePoint.className = 'custom-polyline';
            polylinePoint.setAttribute('altitude-mode', 'absolute');
            polylinePoint.setAttribute('stroke-color', 'yellow');
            polylinePoint.setAttribute('stroke-width', '25'); // Dimensione del punto
        
            polylinePoint.coordinates = [
                { lat: north.lat, lng: north.lng, altitude: north.altitude },
                { lat: east.lat, lng: east.lng, altitude: east.altitude },
                { lat: south.lat, lng: south.lng, altitude: south.altitude },
                { lat: west.lat, lng: west.lng, altitude: west.altitude },
                { lat: north.lat, lng: north.lng, altitude: north.altitude }
            ];
        
            // Aggiungi la polilinea del punto alla mappa
            mappaEarth.appendChild(polylinePoint);
            return polylinePoint;
        }
        else{
            return null;
        }
    }
    
    // Funzione per aggiornare la posizione del punto
    function updatePointPosition(animatedPoint, newPosition) {
        const distance = 0.000001;
        const north = { lat: newPosition.lat + distance, lng: newPosition.lng, altitude: newPosition.altitude };
        const east = { lat: newPosition.lat, lng: newPosition.lng + distance, altitude: newPosition.altitude };
        const south = { lat: newPosition.lat - distance, lng: newPosition.lng, altitude: newPosition.altitude };
        const west = { lat: newPosition.lat, lng: newPosition.lng - distance, altitude: newPosition.altitude };
    
        animatedPoint.coordinates = [
            { lat: north.lat, lng: north.lng, altitude: north.altitude },
            { lat: east.lat, lng: east.lng, altitude: east.altitude },
            { lat: south.lat, lng: south.lng, altitude: south.altitude },
            { lat: west.lat, lng: west.lng, altitude: west.altitude },
            { lat: north.lat, lng: north.lng, altitude: north.altitude }
        ];
    }

    // quando premo il tasto follow, viene posizionata la visuale sull'elicottero
    // logica : ottieni lat, lng, range e heading dalla mappa (valori utilizzati per creare lo start dell'animazione)
    // aggiornamento dati mappa : center, range, tilt, heading

        // DA RIVEDERE
    function flyToFollowPrima(lat, lng, altitude, heading) {

        const mappaEarth = document.querySelector('gmp-map-3d');
        
        if (!mappaEarth) return;

        if (createdElementsRef) {
            if (createdElementsRef.current) {
                cancelCurrentAnimation();
            }
        }
    
        // Ottieni la posizione attuale della mappa e altri parametri
        const startLat = mappaEarth.center.lat;
        const startLng = mappaEarth.center.lng;
        const startZoom = mappaEarth.range; // Assumiamo che 'range' rappresenti lo zoom attuale
        // const startHeading = mappaEarth.heading || 0; // Prende l'heading attuale della mappa o 0 se non definito

        // const duration = 600; // Durata dell'animazione in millisecondi
        // let startTime = null;
    
        // Funzione di animazione per interpolare i valori
        // function animate(timestamp) {
        //     if (!startTime) startTime = timestamp;
        //     const elapsed = timestamp - startTime;
        //     const progress = Math.min(elapsed / duration, 1); // Progressione da 0 a 1
    
            // Interpolazione lineare di latitudine, longitudine, range (zoom) e heading
            const currentLat = lat;
            const currentLng = lng;
            const currentRange = 0; // Valore interpolato di zoom // 1700 è il targetRange
    
            // Aggiornamento dei parametri della mappa
            mappaEarth.center = { lat: currentLat, lng: currentLng, altitude: altitude };
            mappaEarth.range = currentRange;
    
        //     // Continua l'animazione finché non raggiunge il 100%
        //     if (progress < 1) {
        //         requestAnimationFrame(animate);
        //     }
        // }
    
        // Avvia l'animazione
        // requestAnimationFrame(animate);
        // const animationFrameId = requestAnimationFrame(animate);
        // setCurrentAnimationFrameId(animationFrameId); // Salva l'ID dell'animazione
    }
    
    // DA RIVEDERE
    function flyToFollowTerza(lat, lng, altitude, heading) {

        // setPrevAllNode(props.allSnData);

        const mappaEarth = document.querySelector('gmp-map-3d');
        
        if (!mappaEarth) return;

        if (createdElementsRef) {
            if (createdElementsRef.current) {
                cancelCurrentAnimation(); // Cancella l'animazione in corso
            }
        }
    
        // Ottieni la posizione attuale della mappa e altri parametri
        const startLat = mappaEarth.center.lat;
        const startLng = mappaEarth.center.lng;
        //const startZoom = mappaEarth.range; // Assumiamo che 'range' rappresenti lo zoom attuale
        const startHeading = mappaEarth.heading || 0; // Prende l'heading attuale della mappa o 0 se non definito

        const duration = 500; // Durata dell'animazione in millisecondi
        let startTime = null;

        let deltaHeading = calculateDeltaHeading(startHeading, heading);
    
        // Funzione di animazione per interpolare i valori
        function animate(timestamp) {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const progress = Math.min(elapsed / duration, 1); // Progressione da 0 a 1
    
            // Interpolazione lineare di latitudine, longitudine, range (zoom) e heading
            const currentLat = startLat + (lat - startLat) * progress;
            const currentLng = startLng + (lng - startLng) * progress;
            //const currentRange = startZoom + (1700 - startZoom) * progress; // Valore interpolato di zoom // 1700 è il targetRange
            
            // Calcolo dell'heading corrente basato sulla direzione più breve
            const currentHeading = startHeading + deltaHeading * progress;
    
            // Aggiornamento dei parametri della mappa
            mappaEarth.center = { lat: currentLat, lng: currentLng, altitude: altitude };
            mappaEarth.heading = currentHeading;
    
            // Continua l'animazione finché non raggiunge il 100%
            if (progress < 1) {
                requestAnimationFrame(animate);
            }
        }
    
        // Avvia l'animazione
        // requestAnimationFrame(animate);
        const animationFrameId = requestAnimationFrame(animate);
        setCurrentAnimationFrameId(animationFrameId); // Salva l'ID dell'animazione
    }
      

    useEffect(() => {
        let imageData = allData.filter((singleNode) => {
            if ( singleNode.point.hasOwnProperty("images")) {
                if ( singleNode.point.images.RGB_IMAGE != "") {
                    return true
                } else {
                    return false;
                }
            }
        });
        imageData = imageData.slice(0, 4);
        setImageData(imageData);
    }, [props.allData]);

    /*
    useEffect( () => {
        setAllData(props.allData);
    }, [props.allData]);
    */

    useEffect( () => {
        if ( props.infoboxStates[props.ALIAS]) {
            flyToFollowPrima(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, node.Direction);
        }
    }, [props.infoboxStates[props.ALIAS]]);

    useEffect( () => {
        if ( props.infoboxStatesSimu[props.ALIAS]) {
            flyToFollowTerza(node.point.points.LATITUDE, node.point.points.LONGITUDE, node.point.points.ALTITUDE, node.Direction);
        }
    }, [props.infoboxStatesSimu[props.ALIAS]]);

    useEffect( () => {
        props.setInfoboxStates(props.infoboxStates);
        props.setInfoboxStatesSimu(props.infoboxStatesSimu);
        props.setInfoboxStatesPhoto(props.infoboxStatesPhoto);
        props.setInfoboxStatesStreaming(props.infoboxStatesStreaming);
    }, [props.infoboxStates,props.infoboxStatesSimu,props.infoboxStatesPhoto,props.infoboxStatesStreaming]);
/*
    useEffect( () => {
        props.setInfoboxStatesPhoto(props.infoboxStatesPhoto);
    }, [props.infoboxStatesPhoto]);

    useEffect( () => {
        props.setInfoboxStatesStreaming(props.infoboxStatesStreaming);
    }, [props.infoboxStatesStreaming]);
*/

    function flyToCentering()  {
        //map.flyTo([node.point.points.LATITUDE, node.point.points.LONGITUDE],map.getZoom(), flyZoomOptions)
    }

    const openImagePop = (nodeImage, typeImage) => {
        setBigMediaPic(nodeImage);
        setTypeBigImage(typeImage);

    }

    /*
    const toogleVideoPanel = () => {
        setVideoOpen(!videoOpen);
    }
    */
    const _eventClosePreview = () => {
        setBigMediaPic(null);
    }

    const _fnTitleInfoBox2 = () => {
        switch (userTipo) {
            case 1:
                return (
                    <h4>{pdv?.alias}</h4>
                )
            case 2:
                return (
                    <div className="font-23">{pdv?.seriale.seriale}/{pdv?.marca}/{pdv?.alias}</div>
                )
            case 3:
                return (
                    <h4>{pdv?.marca}</h4>
                )
            default:
                return (
                    <div>Loading ...</div>
                )
        }
    } ;

    const flyToLastPointInstant = (lat, lng, altitude, heading) => {

        // console.log("FLY TO LAST POINT");

        const mappaEarth = document.querySelector('gmp-map-3d');
        if (!mappaEarth) return;

        if (createdElementsRef) {
            if (createdElementsRef.current) {
                cancelCurrentAnimation(); // Cancella l'animazione in corso
            }
        }
    
        // Imposta direttamente il centro della mappa all'ultimo punto
        mappaEarth.center = { lat: lat, lng: lng, altitude: altitude };
    
        // Se hai un heading, aggiornalo anche
        if (heading !== null) {
            mappaEarth.heading = heading;
        }
    
        // Puoi aggiornare altri parametri della mappa come zoom, tilt, etc., se necessario
        // mappaEarth.range = 0; // Questo valore può essere modificato a seconda del livello di zoom desiderato
    };
    

    useEffect(() => {
           //console.log("EFFETTO");
            
            // Imposta il nodo e tutti i nodi aggiornati
            setNode(props.singleSnData);
            setAllNode(props.allSnData);
        
            const prevPktNum = prevAllNode[prevAllNode.length - 1]?.point.info.PKT_NUM;
            const currentPktNum = allNode[allNode.length - 1]?.point.info.PKT_NUM;
        
            // console.log("PREV DATA : " , prevAllNode.point.info.PKT_NUM);

            // console.log("ALL DATA : " , allNode.point.info.PKT_NUM);

            let packetDifference = 0;
        
            // Controlla quanti pacchetti sono arrivati in più
            if (currentPktNum !== undefined && prevPktNum !== undefined) {
                packetDifference = currentPktNum - prevPktNum;
            }
        
            // Controlla la direzione e imposta l'angolo convertito
            if (node.Direction < 0) {
                setConvertedAngle(node.Direction + 360);
            } else {
                setConvertedAngle(node.Direction);
            }
        
            let diffLat = 9999;
            let diffLon = 9999;
        
            // Calcola la differenza tra la posizione attuale e l'ultima posizione
            if (lastFlyToPosition) {
                diffLat = Math.abs(lastFlyToPosition[0] - node.point.points.LATITUDE);
                diffLon = Math.abs(lastFlyToPosition[1] - node.point.points.LONGITUDE);
            }
        
            // console.log("VISIBILE WINDOW ? ------> " , isWindowVisible);
            if(isWindowVisible){
                // console.log("PACCHETTI IN PIU ----> " , packetDifference);
               //console.log("PREV DATA : " , prevAllNode[prevAllNode.length - 1].point.info.PKT_NUM);

               //console.log("ALL DATA : " , allNode[allNode.length - 1].point.info.PKT_NUM);
                // Aggiornamento polyline e animazione se il follow è attivo
                if (props.infoboxStates[props.ALIAS]) {
                    if (diffLat > 0.0001 || diffLon > 0.0001) { // MOLTA DISTANZA
                        flyToNavigatorPrima(
                            node.point.points.LATITUDE,
                            node.point.points.LONGITUDE,
                            node.point.points.ALTITUDE,
                            node.Direction
                        );
                        setLastFlyToPosition([node.point.points.LATITUDE, node.point.points.LONGITUDE]);
                    } else { // POCA DISTANZA
                        flyToNavigatorPrima(
                            node.point.points.LATITUDE,
                            node.point.points.LONGITUDE,
                            node.point.points.ALTITUDE,
                            null
                        );
                    }
                }
            
                // Gestione della simulazione (quando infoboxStatesSimu è attivo)
                if (props.infoboxStatesSimu[props.ALIAS]) {
                    if (diffLat > 0.0001 || diffLon > 0.0001) { // MOLTA DISTANZA
                        if (packetDifference <= 0) {
            
                        } else if (packetDifference === 1) {
                            flyToNavigatorTerza(
                                node.point.points.LATITUDE,
                                node.point.points.LONGITUDE,
                                node.point.points.ALTITUDE,
                                node.Direction,
                                () => {}
                            );
                        } else if (packetDifference > 1) { // CASO IN CUI ARRIVANO PIU PACCHETTI ED IL PRIMO E' MOLTO DISTANTE DALL'ULTIMO
                            const newPoints = allNode.filter(point => point.point.info.PKT_NUM > prevPktNum);
                            flyToNavigatorTerzaMultiplePoints(newPoints);
                        }
                        setLastFlyToPosition([node.point.points.LATITUDE, node.point.points.LONGITUDE]);
            
                    } else { // POCA DISTANZA
                        if (packetDifference <= 0) {
                            updatePointPolylinesNoFollow();
                            updateMainPolylineNoPoints();
                        } else if (packetDifference === 1) {
                            flyToNavigatorTerza(
                                node.point.points.LATITUDE,
                                node.point.points.LONGITUDE,
                                node.point.points.ALTITUDE,
                                null,
                                () => {}
                            );
                        } else if (packetDifference > 1) {
                            const newPoints = allNode.filter(point => point.point.info.PKT_NUM > prevPktNum);
                            flyToNavigatorTerzaMultiplePoints(newPoints);
                        }
                    }
                }
            }
            else{
                // updatePointPolylinesNoFollow();
                // updateMainPolylineNoPoints();

                // updatePointPolylines();
                // updateMainPolyline();
            }
        
            if (!props.infoboxStates[props.ALIAS] && !props.infoboxStatesSimu[props.ALIAS]) {
                updatePointPolylinesNoFollow();
                updateMainPolylineNoPoints();
            }
        
            if (node?.point?.images) {
                let n = {};
                n.info = node.point.info;
                n.images = node.point.images;
                setImagePoint(n);
            }
        
            // Aggiorna i valori precedenti per il prossimo ciclo
            setPrevHeading(node.point.points.HEADING);
            setPrevLat(node.point.points.LATITUDE);
            setPrevLon(node.point.points.LONGITUDE);
            setPrevAlt(node.point.points.ALTITUDE);
            setPrevPKTNUM(node.point.info.PKT_NUM);
            setPrevAllNode(allNode);
        
    
        return () => {
            // isComponentMounted = false; // Quando il componente viene smontato, disattiva ulteriori aggiornamenti
            removeAllMapElements(); // Pulisci eventuali animazioni attive
        };
    
    }, [props.singleSnData]);
    
    
    // Funzione centralizzata per ottenere mappa Earth e controllare la sua esistenza
    const getMappaEarth = () => {
        const mappaEarth = document.querySelector('gmp-map-3d');
        if (!mappaEarth) {
            throw new Error('Mappa non trovata!');
        }
        return mappaEarth;
    };
    
    // Funzione per rimuovere elementi di riferimento (polyline, animazioni, etc.)
    const removeAllMapElements = () => {
        // Rimuovi la polyline principale
        if (mainPolylineRef.current) {
            mainPolylineRef.current.remove();
            mainPolylineRef.current = null;
        }
    
        // Rimuovi tutte le polylines dei punti
        if (pointPolylinesRef.current) {
            removeElementsFromRef(pointPolylinesRef);
        }
    
        // Rimuovi gli elementi creati per l'animazione (polylines e punti animati)
        if (createdElementsRef?.current) {
            removeElementsFromRef(createdElementsRef);
        }
    };
    
    // Funzione per rimuovere elementi di una lista di riferimenti
    const removeElementsFromRef = (refList) => {
        refList.current.forEach(el => el?.remove?.());
        refList.current = []; // Reset del riferimento
    };
    
    // Funzione per creare un punto di polilinea
    const createPolylinePoint = (punto, serialeId, strokeColor = 'white', strokeWidth = '15') => {
        const distance = 0.000001;
        const north = { lat: punto.LATITUDE + distance, lng: punto.LONGITUDE, altitude: punto.ALTITUDE };
        const east = { lat: punto.LATITUDE, lng: punto.LONGITUDE + distance, altitude: punto.ALTITUDE };
        const south = { lat: punto.LATITUDE - distance, lng: punto.LONGITUDE, altitude: punto.ALTITUDE };
        const west = { lat: punto.LATITUDE, lng: punto.LONGITUDE - distance, altitude: punto.ALTITUDE };
    
        const polylinePoint = document.createElement('gmp-polyline-3d');
        polylinePoint.className = 'custom-polyline';
        polylinePoint.setAttribute('data-seriale-id', serialeId);
        polylinePoint.setAttribute('altitude-mode', 'absolute');
        polylinePoint.setAttribute('stroke-color', strokeColor);
        polylinePoint.setAttribute('stroke-width', strokeWidth);
    
        polylinePoint.coordinates = [
            { lat: north.lat, lng: north.lng, altitude: north.altitude },
            { lat: east.lat, lng: east.lng, altitude: east.altitude },
            { lat: south.lat, lng: south.lng, altitude: south.altitude },
            { lat: west.lat, lng: west.lng, altitude: west.altitude },
            { lat: north.lat, lng: north.lng, altitude: north.altitude }
        ];
    
        return polylinePoint;
    };
    
    // Funzione per ottenere le coordinate della polyline
    const getPolylineCoordinates = (sliceCount = 1, excludeLastPoint = true) => {
        let coordinates = allNode.map(singlePoint => {
            const { LATITUDE, LONGITUDE, ALTITUDE } = singlePoint.point.points;
            return { lat: LATITUDE, lng: LONGITUDE, altitude: ALTITUDE };
        });
    
        if (excludeLastPoint) {
            return coordinates.slice(0, -sliceCount);
        }
    
        return coordinates;
    };
    
    // Funzione per creare o aggiornare una polyline
    const createOrUpdateMainPolyline = (coordinates, serialeId) => {
        const mappaEarth = getMappaEarth();

        // Verifica che le coordinate siano valide e abbiano almeno 2 punti
        if (!Array.isArray(coordinates) || coordinates.length < 2) {
           //console.log("Errore: Non ci sono abbastanza coordinate per creare o aggiornare la polyline.");
            return; // Esci dalla funzione se le coordinate non sono valide
        }
    
        if (mainPolylineRef.current) {
            mainPolylineRef.current.coordinates = coordinates;
        } else {
            const mainPolyline = document.createElement('gmp-polyline-3d');
            mainPolyline.className = 'custom-polyline';
            mainPolyline.setAttribute('data-seriale-id', serialeId);
            mainPolyline.setAttribute('altitude-mode', 'absolute');
            mainPolyline.setAttribute('stroke-color', 'red');
            mainPolyline.setAttribute('stroke-width', '10');
            mainPolyline.coordinates = coordinates;
            mappaEarth.appendChild(mainPolyline);
            mainPolylineRef.current = mainPolyline;
        }
    };
    
    // Funzione per aggiornare la polyline principale
    const updateMainPolyline = (excludeLastPoint = true, sliceCount = 1) => {
        const coordinates = getPolylineCoordinates(sliceCount, excludeLastPoint);
        createOrUpdateMainPolyline(coordinates, props.SN);
    };
    
    // Funzione per aggiornare i punti di polilinea
    const updatePointPolylines = (highlightLast = false, sliceCount = 1) => {
        const mappaEarth = getMappaEarth();
        const serialeId = props.SN;
        removeElementsFromRef(pointPolylinesRef);
    
        const lastIndex = allNode.length - sliceCount;
    
        allNode.slice(0, -sliceCount).forEach((singlePoint, index) => {
            const punto = singlePoint.point.points;
            const strokeColor = (highlightLast && index === lastIndex - 1) ? 'yellow' : 'white';
            const strokeWidth = (highlightLast && index === lastIndex - 1) ? '25' : '15';
    
            const polylinePoint = createPolylinePoint(punto, serialeId, strokeColor, strokeWidth);
            mappaEarth.appendChild(polylinePoint);
            pointPolylinesRef.current.push(polylinePoint);
        });
    };
    
    // Funzione per aggiornare la polyline principale senza slice
    const updateMainPolylineNoPoints = () => {
        updateMainPolyline(false, 0);
    };
    
    // Funzione per aggiornare i punti di polilinea senza seguire l'ultimo
    const updatePointPolylinesNoFollow = () => {
        const mappaEarth = getMappaEarth();
        const serialeId = props.SN;
        removeElementsFromRef(pointPolylinesRef);
    
        const lastIndex = allNode.length - 1;
    
        allNode.forEach((singlePoint, index) => {
            const punto = singlePoint.point.points;
            const strokeColor = (index === lastIndex) ? 'yellow' : 'white';
            const strokeWidth = (index === lastIndex) ? '25' : '15';
    
            const polylinePoint = createPolylinePoint(punto, serialeId, strokeColor, strokeWidth);
            mappaEarth.appendChild(polylinePoint);
            pointPolylinesRef.current.push(polylinePoint);
        });
    };
    
    // Funzione per aggiornare la polyline principale con slice in base a più punti
    const updateMainPolylineMultiple = (newPointsLength) => {
        updateMainPolyline(true, newPointsLength);
    };
    
    // Funzione per aggiornare i punti di polilinea con slice in base a più punti
    const updatePointPolylinesMultiple = (newPointsLength) => {
        updatePointPolylines(false, newPointsLength);
    };    

    useEffect(() => {
        setGradiLat(Math.floor(node.point.points.LATITUDE));
        setMinutiLat(Math.floor((node.point.points.LATITUDE - gradiLat)*60));
        setSecondiLat((((node.point.points.LATITUDE - gradiLat)*60) - minutiLat)*60);

        setGradiLon(Math.floor(node.point.points.LONGITUDE));
        setMinutiLon(Math.floor((node.point.points.LONGITUDE - gradiLon)*60));
        setSecondiLon((((node.point.points.LONGITUDE - gradiLon)*60) - minutiLon)*60);
    }, [props.singleSnData]);

    function generateUniqueId() {
        return Math.random().toString(36).substr(2, 9); // Genera un ID univoco
      }

    useEffect(() => {
        // Funzione di cleanup chiamata solo quando il componente viene smontato
        return () => {
            props.toggleFollowFalse(props.ALIAS);
            props.toggleFollowFalseSimu(props.ALIAS);
        };
    }, []); // Dipendenze vuote: effetto eseguito solo al montaggio e smontaggio
      
    // Funzione per cambiare il range
    const handleRangeChange = (deltaRange) => {
        const map = document.querySelector('gmp-map-3d');

        // Calcola il nuovo range
        const newRange = map.range + deltaRange;
    
        // Aggiorna lo stato
        setRange(newRange);
    
        if (isAnimating) {
            stopAnimation(); // Interrompe temporaneamente l'animazione
        }
    
        if (map) {
            // Usa il valore calcolato localmente per aggiornare la mappa
            map.range = newRange; // Aggiorna il range della mappa
        }
    
        // Riavvia l'animazione dopo aver aggiornato il range
        startAnimation();
    };    

    // Funzione per cambiare il tilt
    const handleTiltChange = (deltaTilt) => {
        const map = document.querySelector('gmp-map-3d');
        
        if (isAnimating) {
            stopAnimation(); // Interrompe temporaneamente l'animazione
        }

        if (map) {
            const currentTilt = map.tilt || 0; // Usa un valore di default se il tilt non è definito
            const newTilt = Math.max(0, Math.min(currentTilt + deltaTilt, 90)); // Mantiene il tilt tra 0 e 90
            map.tilt = newTilt; // Aggiorna il tilt della mappa
        }

        // Riavvia l'animazione dopo aver aggiornato il tilt
        startAnimation();
    };

    // Funzione per interrompere l'animazione corrente
    const stopAnimation = () => {
        setIsAnimating(false);
    };

    // Funzione per riavviare l'animazione
    const startAnimation = () => {
        setIsAnimating(true);
    };

    const toggleTrackVisibility = () => {
        setTrackVisible(prev => !prev);
    };


    return (
                <div className={"d-flex flex-column contenitoreInfobox item"}>
                    
                    <div className={"d-flex flex-column InfoBox " + classStatePanel} >
                        
                        <div className={"d-flex flex-row justify-center-content title "  + linkStatus} onClick={toggleTrueFalse} >
                            <Infoboxtitle pdv={pdv} userTipo={userTipo} htkBackend={props.htkBackend} isDrone={streamingDrone.includes(seriale)}/>
                            <div className="d-flex align-items-center flex-center ml-auto p-2">
                                {   
                                    node?.point?.video ?
                                    <span className="popuptext-pc-new" id="myPopup-pc"> 
                                        <img src="./icons/iconaRegistrazioneVideo.gif" className="iconRec-pc-new" alt="Mia Immagine" />
                                    </span>
                                     : <></>
                                } 
                                {   
                                    node?.point?.images ?
                                    <span className="popuptext2-pc" id="myPopup-pc"> 
                                        <img src="./icons/iconaImageRec.webp" className="iconRec2-pc" alt="Mia Immagine" />
                                    </span>
                                    : <></>
                                } 
                            </div>
                            <div className="d-flex align-items-center ml-auto p-2">
                                <i className={"d-flex ms-auto align-item-center fas fa-2x " + iconLinkStatus}></i>
                            </div>
                        </div>

                        <div className="information d-flex flex-center w-100">
                            
                            <div className="align-self-center">Live <Moment
                                interval={1000}
                                parse="YYYY-MM-DD HH:mm:ss"
                                date={dateTimeStamp}
                                fromNow
                            />
                            </div>
                        </div>

                        <div className="d-flex flex-row w-100 flex-center">

                            {/*<button className={"w-25 flex-fill m-1 btnAction" + (photoOpen ? " toogleTrue " : "")} onClick={tooglePhotoPanel}>Photo</button>*/}

                            <button 
                                className={"w-25 flex-fill m-1 btnAction" + (props.infoboxStatesPhoto[props.ALIAS] ? " toogleTrue " : "")} 
                                onClick={() => {
                                    props.togglePhoto(props.ALIAS)
                                }}
                            >
                                Photo
                            </button>

                            {/*
                            <button
                                className={
                                    "w-25 flex-fill m-1 btnAction" +
                                    (props.infoboxStates[props.ALIAS] ? " toogleTrue " : "")
                                }
                                disabled={
                                    Object.entries(props.infoboxStates).some(
                                    ([key, value]) => key !== props.ALIAS && value === true
                                    ) ||
                                    Object.entries(props.infoboxStatesSimu).some(
                                    ([key, value]) => key !== props.ALIAS && value === true
                                    ) || props.infoboxStatesSimu[props.ALIAS]
                                }
                                onClick={() => {
                                    toggleTrackVisibility();
                                    props.toggleFollow(props.ALIAS);
                                    // flyTo(48.8566, 2.3522); // Usa i valori dinamici di props per le coordinate e zoom
                                }}
                            >
                                FollowPrima
                            </button>

                            <button
                                className={
                                    "w-25 flex-fill m-1 btnAction" +
                                    (props.infoboxStatesSimu[props.ALIAS] ? " toogleTrue " : "")
                                }
                                disabled={
                                    Object.entries(props.infoboxStates).some(
                                    ([key, value]) => key !== props.ALIAS && value === true
                                    ) ||
                                    Object.entries(props.infoboxStatesSimu).some(
                                    ([key, value]) => key !== props.ALIAS && value === true
                                    ) || props.infoboxStates[props.ALIAS]
                                }
                                onClick={() => {
                                    props.toggleFollowSimu(props.ALIAS);
                                    // flyTo(48.8566, 2.3522); // Usa i valori dinamici di props per le coordinate e zoom
                                }}
                            >
                                FollowTerza
                            </button>
                            */}

                            <button
                                className={`w-25 flex-fill m-1 btnAction${isPrimaActive ? " toogleTrue" : ""}`}
                                disabled={isAnyOtherActive || isTerzaActive}
                                onClick={handlePrimaClick}
                            >
                                FollowPrima
                            </button>
                            <button
                                className={`w-25 flex-fill m-1 btnAction${isTerzaActive ? " toogleTrue" : ""}`}
                                disabled={isAnyOtherActive || isPrimaActive}
                                onClick={handleTerzaClick}
                            >
                                FollowTerza
                            </button>

                            {serialiStreaming.includes(seriale) && (
                                linkStatusStreaming === "CONNECTED" ? (
                                    <button className={`w-25 flex-fill m-1 btnAction ${infoboxStatesStreaming[props.ALIAS] ? 'toogleTrue' : ''}`} onClick={() => {props.toggleStreaming(props.ALIAS)}}>
                                    Streaming
                                    </button>
                                ) : (
                                    <button className="w-25 flex-fill m-1 btnAction" disabled>
                                    Streaming
                                    </button>
                                )
                                )}

                        </div>


                        <div className={props.infoboxStatesPhoto[props.ALIAS] ? " contFoto" : " d-none contFoto"}>
                            <div className="">
                                <BigMedia pic={bigMediaPic} typeBigImage={typeBigImage} eventClosePreview={_eventClosePreview} />
                                <Media typeImage={typeImage} pic={imagePoint} openPopImage={openImagePop}  />
                            </div>
                        </div>
                        
                        <div className="divider mt-1 font-24"></div>

                        {   
                            serialiStreaming.includes(seriale) && linkStatusStreaming === "CONNECTED" && infoboxStatesStreaming[props.ALIAS] &&


                            // player hls per PC , windy

                            <div className="videoStreamingContainer">
                                
                                <VideoPlayerHls 
                                    videoStreaming={streamingH265.includes(seriale) ? videoStreamingH265 : videoStreamingH264} 
                                    nomeDispositivo={seriale} 
                                    videoStreamingName={videoStreamingName} 
                                    isDrone={streamingDrone.includes(seriale)} // Verifica se il seriale appartiene a un drone
                                    
                                    // infoBoxId={infoBoxId} 
                                    // videoPlayerKey={videoPlayerKey}
                                /> 

                            </div>
                        
                        } 

                        {      
                            !infoboxStatesStreaming[props.ALIAS] &&
                            <>
                                <div className="informationLat d-flex flex-row mt-2">
                                    <p className="labelInfoBox divInfoBoxElements1">Latitude</p>
                                    <p className="font-24 divInfoBoxElements2">{gradiLat.toFixed(0)}°</p>
                                    <p className="font-24 divInfoBoxElements3">{minutiLat.toFixed(0)}'</p>
                                    <p className="font-24 divInfoBoxElements4">{secondiLat.toFixed(3)}''</p>
                                </div>

                                <div className="informationLon d-flex flex-row">
                                    <p className="labelInfoBox divInfoBoxElements1">Longitude</p>
                                    <p className="font-24 divInfoBoxElements2">{gradiLon.toFixed(0)}°</p>
                                    <p className="font-24 divInfoBoxElements3">{minutiLon.toFixed(0)}'</p>
                                    <p className="font-24 divInfoBoxElements4">{secondiLon.toFixed(3)}''</p>
                                </div>

                                <div className="information d-flex flex-row justify-content-between">
                                    <div className="d-flex flex-column">
                                        <div className="labelInfoBox divInfoBoxElements">Heading </div><div className="font-24 divInfoBoxElements"> {convertedAngle && convertedAngle.toFixed(3)}</div>
                                    </div>
                                    {
                                        userTipo!=1 ?
                                            (<div className="d-flex flex-column">
                                                <div className="labelInfoBox divInfoBoxElements">Altitude </div><div className="font-24 divInfoBoxElements">{node.point.points.ALTITUDE.toFixed(2)}</div>
                                            </div>)
                                            : (<div/>)
                                    }
                                    <div className="d-flex flex-column">
                                        <div className="labelInfoBox divInfoBoxElements">Speed </div><div className="font-24 divInfoBoxElements">{(node.point.points.SPEED*3.6).toFixed(0) < 5 ? "0" : (node.point.points.SPEED*3.6).toFixed(0)} km/h</div>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className="labelInfoBox divInfoBoxElements">Speed </div><div className="font-24 divInfoBoxElements">{(node.point.points.SPEED*3.6).toFixed(0) < 5 ? "0" : (node.point.points.SPEED*1.944).toFixed(0)} kn</div>
                                    </div>
                                </div>
                            </>
                        }

                        <div className="information" >

                            <div className="divider mt-2 mb-2"></div>

                            {props.infoboxStatesSimu[props.ALIAS] && (
                                <div className="d-flex flex-row">
                                    {/* Pulsanti per aumentare/diminuire il range */}
                                    <button
                                        className="w-25 flex-fill m-1 btnAction"
                                        onClick={() => handleRangeChange(20)} // Incrementa il range di 20
                                    >
                                        Increase Range
                                    </button>
                                    <button
                                        className="w-25 flex-fill m-1 btnAction"
                                        onClick={() => handleRangeChange(-20)} // Diminuisci il range di 20
                                    >
                                        Decrease Range
                                    </button>

                                    {/* Pulsanti per aumentare/diminuire il tilt */}
                                    <button
                                        className="w-25 flex-fill m-1 btnAction"
                                        onClick={() => handleTiltChange(2)} // Incrementa il tilt di 10
                                    >
                                        Increase Tilt
                                    </button>
                                    <button
                                        className="w-25 flex-fill m-1 btnAction"
                                        onClick={() => handleTiltChange(-2)} // Diminuisci il tilt di 10
                                    >
                                        Decrease Tilt
                                    </button>
                                </div>
                            )}

                            <ConnectionState userTipo={userTipo} node={node} setLinkStatus = {changeLinkStatus} setLinkStatusStreaming = {changeLinkStatusStreaming}/>
                        </div>

                    </div>

                </div>
    )
}

