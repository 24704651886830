import React, {useState, useEffect, useRef} from "react"

export default function Media(props) {
    const [mediaPics, setMediaPics] = useState([props.pic]);
    const [typeImage, setTypeImage] = useState(props.typeImage);
    const [idImages, setIdImages] = useState([]);


    useEffect(() => {
        setTypeImage(props.typeImage);
    }, [props.typeImage]);

    // aggiorna la reference al cambiamento di userData
    useEffect(() => {

            //console.log("SLICE IMG:", mediaPics, "ID:",idImages );
            let images = props?.pic?.images;
            let info = props?.pic?.info;
            if (images) {
                images.PKT_NUM = info?.PKT_NUM;
            }
            let idImagesTemp = idImages;
            //console.log("htk", "idImagesTemp", idImagesTemp);
            let exist = false;
            for (let i = 0; i < idImagesTemp.length; i++) {
                if ( idImages[i] == info?.PKT_NUM) {
                        exist = true;
                        break;
                }
            }
            if ( !exist) {
                idImagesTemp.push(info?.PKT_NUM)
                if ( typeof(images) != "undefined" && images) {
                    //console.log("htk", "mediaPics", mediaPics);
                    let temp = [images, ...mediaPics];
                    temp = temp.slice(0, 3);
                    setMediaPics(temp);

                }
            }
            setIdImages(idImagesTemp);
        }, [props.pic])

    useEffect(() => {
        //console.log("MEDIA:", mediaPics)
    }, [mediaPics])

    function keyGeneratorMedia() {

        return Math.floor(Math.random()*2000);
    }

    function getImage(picSingle) {
        if ( picSingle) {

            if ( typeImage == "RGB_IMAGE") {
                return (<img id={picSingle.PKT_NUM} key={picSingle.PKT_NUM} className="m-1 border" src={`data:image/png;base64,${picSingle.RGB_IMAGE}`}  height="64" width="64" onClick={() => props.openPopImage(picSingle, typeImage)}  />);
            } else if ( typeImage == "IR_IMAGE") {
                return (<img id={picSingle.PKT_NUM} key={picSingle.PKT_NUM} className="m-1 border" src={`data:image/png;base64,${picSingle.IR_IMAGE}`}  height="64" width="64" onClick={() => props.openPopImage(picSingle, typeImage)}  />)
            }
        }
    }

    function eventClosePreview(){

    }

    return (
        <div>
            {
                mediaPics ? (
                    <div className="d-flex flex-row border">
                        <div className="d-flex flex-column p-2">{}
                            <button className={"btn btn-sm mb-1 " + (typeImage=="IR_IMAGE" ? "btn-active" : "")} onClick={ () => {setTypeImage("IR_IMAGE")}}>IR</button>
                            <button className={"btn btn-sm mb-1 " + (typeImage=="RGB_IMAGE" ? "btn-active" : "")} onClick={ () => {setTypeImage("RGB_IMAGE")}} >RGB</button>
                        </div>
                        <div className="d-flex flex-row p-2">
                            {
                                mediaPics?.map((picSingle) => {
                                    return getImage(picSingle)
                                })
                            }
                        </div>
                    </div>
                ) : <div />
            }

        </div>);
}
