// src/Map3DComponent.js
import React, { useEffect, useRef } from 'react';

const Map3DComponent = ({ htkApiMaps }) => {  // Ricevi la chiave API dalle props

  const mapRef = useRef(null); // Riferimento al DOM di gmp-map-3d

  useEffect(() => {
    // Aggiungi lo script della mappa al documento
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${htkApiMaps}&v=alpha&libraries=maps3d`;
    script.async = true;
    script.onload = () => {
      console.log('Google Maps API script loaded');

      // Collega gli eventi al caricamento dello script
      const map3DElement = mapRef.current;

      if (map3DElement) {
        // Collega gli eventi
        //map3DElement.addEventListener('gmp-click', handleClickEvent);
        //map3DElement.addEventListener('gmp-centerchange', handleCenterChangeEvent);
        //map3DElement.addEventListener('gmp-headingchange', handleHeadingChangeEvent);
        //map3DElement.addEventListener('gmp-rangechange', handleRangeChangeEvent);
        //map3DElement.addEventListener('gmp-rollchange', handleRollChangeEvent);
        //map3DElement.addEventListener('gmp-steadychange', handleSteadyChangeEvent);
        //map3DElement.addEventListener('gmp-tiltchange', handleTiltChangeEvent);
      }
    };
    document.body.appendChild(script);

    // Cleanup function per rimuovere lo script quando il componente viene smontato
    return () => {
      document.body.removeChild(script);

      const map3DElement = mapRef.current;
      if (map3DElement) {
        //map3DElement.removeEventListener('gmp-click', handleClickEvent);
        // map3DElement.removeEventListener('gmp-centerchange', handleCenterChangeEvent);
        // map3DElement.removeEventListener('gmp-headingchange', handleHeadingChangeEvent);
        //map3DElement.removeEventListener('gmp-rangechange', handleRangeChangeEvent);
        // map3DElement.removeEventListener('gmp-rollchange', handleRollChangeEvent);
        // map3DElement.removeEventListener('gmp-steadychange', handleSteadyChangeEvent);
        // map3DElement.removeEventListener('gmp-tiltchange', handleTiltChangeEvent);
      }

    };
  }, []);

  // Funzioni per gestire gli eventi
  const handleClickEvent = (event) => {
    console.log('Click event detected:', event);
  };

  const handleCenterChangeEvent = (event) => {
    console.log('Center change event detected:', event);
  };

  const handleHeadingChangeEvent = (event) => {
    console.log('Heading change event detected:', event);
  };

  const handleRangeChangeEvent = (event) => {
    console.log('Range change event detected:', event);
  };

  const handleRollChangeEvent = (event) => {
    console.log('Roll change event detected:', event);
  };

  const handleSteadyChangeEvent = (event) => {
    console.log('Steady change event detected:', event);
  };

  const handleTiltChangeEvent = (event) => {
    console.log('Tilt change event detected:', event);
  };

  return (
    <div>
      <gmp-map-3d
        ref={mapRef}
        center="37.841157, -122.551679" 
        tilt="67.5" 
        style={{ width: '100%', height: '100vh' }}
      ></gmp-map-3d>
    </div>
  );
};

export default Map3DComponent;
