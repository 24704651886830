import React, {useState, useEffect, useRef} from "react"

export default function MediaResponsive(props) {
    const [mediaPics, setMediaPics] = useState([props.pic]);
    const [idImages, setIdImages] = useState([]);

    /*
    useEffect(() => {
        setTypeImage(props.typeImage);
    }, [props.typeImage]);
    */

    // aggiorna la reference al cambiamento di userData
    useEffect(() => {

            let images = props?.pic?.images;
            let info = props?.pic?.info;
            if ( images) {
                images.PKT_NUM = info?.PKT_NUM;
            }
            let idImagesTemp = idImages;
            let exist = false;
            for (let i = 0; i < idImagesTemp.length; i++) {
                if ( idImages[i] == info?.PKT_NUM) {
                        exist = true;
                        break;
                }
            }
            if ( !exist) {
                idImagesTemp.push(info?.PKT_NUM)
                if ( typeof(images) != "undefined" && images) {
                    let temp = [images, ...mediaPics];
                    temp = temp.slice(0, 1);
                    setMediaPics(temp);
                }
            }
            setIdImages(idImagesTemp);


        }, [props.pic])

    useEffect(() => {
        //console.log("MEDIA:", mediaPics)
    }, [mediaPics])

    function keyGeneratorMedia() {

        return Math.floor(Math.random()*2000);
    }

    function getImage(picSingle) {
        
        if ( picSingle) {

            /*
            if ( typeImage == "RGB_IMAGE") {
                return (<img id={picSingle.PKT_NUM} key={picSingle.PKT_NUM} className="m-1 border" src={`data:image/png;base64,${picSingle.RGB_IMAGE}`}  height="20" width="20" onClick={() => props.openPopImage(picSingle, typeImage)}  />);
            } else if ( typeImage == "IR_IMAGE") {
                return (<img id={picSingle.PKT_NUM} key={picSingle.PKT_NUM} className="m-1 border" src={`data:image/png;base64,${picSingle.IR_IMAGE}`}  height="20" width="20" onClick={() => props.openPopImage(picSingle, typeImage)}  />)
            }
*/

            return(
                <>
                <div className="infoDiv d-flex flex-center">
                    <img id={picSingle.PKT_NUM} key={picSingle.PKT_NUM} className="m-1 border" src={`data:image/png;base64,${picSingle.RGB_IMAGE}`} height="30" width="30"/* onClick={() => props.openPopImage(picSingle, typeImage)} */ />
                </div>
                <div className="infoDiv d-flex flex-center">
                    <img id={picSingle.PKT_NUM} key={picSingle.PKT_NUM} className="m-1 border" src={`data:image/png;base64,${picSingle.IR_IMAGE}`} height="30" width="30" /* onClick={() => props.openPopImage(picSingle, typeImage)} */ />
                </div>
                </>
                )

        }
    }

    function eventClosePreview(){

    }

    return (
        <div>
            {
                mediaPics ? (
                    <div className="d-flex flex-row border sizePanelPhoto w-100">
                        <div className="d-flex flex-row p-1 w-100">
                            {
                                mediaPics?.map((picSingle) => {
                                    return getImage(picSingle)
                                })
                            }
                        </div>
                    </div>
                ) : <div />
            }

        </div>);
}
